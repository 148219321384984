import React from 'react';
import cookie from 'react-cookies';
import { withTranslation } from 'react-i18next';
import Luxy from 'luxy.js';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import './InformeFinanciero.css';

import HeroBgImageVideo from '../video/video_fachada-polanco.jpg';
import HeroVideo from '../video/video_fachada-polanco.mp4';

import LogoPalacio01 from '../images/white-logo-01.svg';
import MouseIcon from '../images/scroll-icon.svg';

import NextIcon from '../images/forward-icon.svg';
import PrevIcon from '../images/backward-icon.svg';

import ControlsPage from '../components/ControlsPage';
import ControlsPageMobile from '../components/ControlsPageMobile';
import VideoPlayer from '../components/VideoPlayer';

import { isBrowser, isMobile, isTablet } from "react-device-detect";

import Quote01 from '../images/quote01.png';
import Quote02 from '../images/quote02.png';

import ReporteEstadoFinanciero from '../pdf/Estados Financieros Palacio 2020.pdf';

import parse from 'html-react-parser';

class InformeFinanciero extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      is_parallax_active: true,
    };
    // this.videoRef = React.createRef();
    // this.heroRef = React.createRef();
  }

  componentDidMount = () => {
    this.setLanguage();
    window.scrollTo(0, 0);
    window.addEventListener('scroll', this.onScroll);
    window.addEventListener('resize', this.onWindowResize, true);
    this.onWindowResize();
  }

  componentWillUnmount = () => {
    window.removeEventListener('scroll', this.onScroll);
    window.removeEventListener('resize', this.onWindowResize);
  }

  componentDidUpdate() {
      
  }

  setLanguage = () => {
    const { t, i18n } = this.props;
    const lang = cookie.load('lng');
    if (lang == undefined) {
      i18n.changeLanguage('es');
      cookie.save('lng', 'es');
    } else {
      if (lang == 'es') {
        i18n.changeLanguage('es');
      } else {
        i18n.changeLanguage('en');
      }
    }
  }

  onScroll = (event) => {
    const h = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    const positionY = window.pageYOffset;
    if (positionY >= h) {
    //   this.videoRef.current.pause();
    //   this.videoRef.current.style.display = 'none';
    //   this.heroRef.current.style.display = 'none';
    } else {
    //   this.videoRef.current.play();
    //   this.videoRef.current.style.display = 'block';
    //   this.heroRef.current.style.display = 'block';
    }
  }

  onWindowResize = () => {
    this.w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    
    if (this.w <= 768) {
      this.setState({
        is_parallax_active: false
      });  
    } else {
      this.setState({
        is_parallax_active: true
      });  
    }

    if (isBrowser) {
      // Luxy.init({wrapperSpeed: 0.2});
    }
  }

  onLoadedMetadata = (event) => {
    
  }

  onImprimir = (event) => {
    event.preventDefault();
    window.open(event.target.href).print();
    return false;
  }

  render () {

    const { t } = this.props;

    return (
      <>

      <div className='hero-informe-financiero'>

        {/* <video ref={this.videoRef} className="bg-video" poster={HeroBgImageVideo} autoPlay data-autoplay muted loop playsInline webkit-playsinline="true" preload="metadata" onLoadedData={this.onLoadedMetadata}>
          <source src={HeroVideo} type="video/mp4"></source>
        </video> */}
        
        <div className='container-fluid hero-container' ref={this.heroRef}>
          <div className='row hero'>
            <div className='col-uno'>
                <span></span>
            </div>
            <div className='col-dos'>
              <div className='texto'>
                <span>{parse(t('08.hero.title.span.01'))}</span>
                <span>{parse(t('08.hero.title.span.02'))}</span>
                <span>{parse(t('08.hero.title.span.03'))}</span>
                <span>{parse(t('08.hero.title.span.04'))}</span>
              </div>
            </div>
          </div>
          <div className='mouse-scroll-icon'>
            <img src={MouseIcon}></img>
            <span>{t('08.span.mouse.message')}</span>
          </div>
        </div>

        <div className='logo-hero'>
          <img src={LogoPalacio01}></img>
        </div>

      </div>

      <div className='informefinanciero' id="luxy">
        <div style={{ background: '#fff' }}>

          <div className='texto-lateral-seccion08'>{t('08.texto.lat')}</div>

          <div className='container'>
            <div className='row section-01'>
              <div className='col-lg-12 col-uno'>
                <h1 className='h-pag'>
                  {parse(t('08.h1'))}
                </h1>
              </div>
              <div className='col-lg-12 col-dos'>
                <p>
                    {parse(t('08.s1.text1'))}
                </p>
                <a href={ReporteEstadoFinanciero} target='_blank' download className='descarga'>{t('descarga.el.pdf')}</a>
                <a href={ReporteEstadoFinanciero} onClick={this.onImprimir} className='imprimir'>{t('imprimir')}</a>
              </div>
            </div>
          </div>

          <ControlsPage prev={this.props.prev} next={this.props.next}></ControlsPage>

        </div>
      </div>

      <ControlsPageMobile prev={this.props.prev} next={this.props.next}></ControlsPageMobile>

      </>
    );
  }
}

export default withTranslation()(InformeFinanciero);