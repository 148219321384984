import React from 'react';
import './VideoPlayer.css';
import PlayIcon from '../images/play-icon.svg';


class VideoPlayer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      controls: false,
      buttonDisplay: 'block',
    };
    this.myRef = React.createRef();  
  }

  onClickButton = (event) => {
    event.preventDefault();
    this.myRef.current.play();
    this.setState({
        controls: true, 
        buttonDisplay: 'none',
    });
  }

  render () {
    return (
        <div className='video-container'>
            <video controls={this.state.controls} ref={this.myRef} className='video-player' poster={this.props['poster']} data-autoplay playsInline webkit-playsinline="true" preload="metadata" onLoadedData={this.onLoadedMetadata}>
                <source src={this.props['video']} type="video/mp4"></source>
            </video>
            <a href='#' className='play-video' onClick={this.onClickButton} style={{ display: this.state.buttonDisplay }}>
                <img src={PlayIcon}></img>
            </a>
        </div>
    );
  }
}

export default VideoPlayer;