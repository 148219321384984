import React from 'react';
import ReactDOM from 'react-dom';
import "./i18n";
import App from './App';

ReactDOM.render(
    <React.Suspense fallback="loading...">
        <React.StrictMode>
            <App />
        </React.StrictMode>
    </React.Suspense>,
    document.getElementById('root')
);
