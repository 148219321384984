import React from 'react';
import cookie from 'react-cookies';
import { withTranslation } from 'react-i18next';
import Luxy from 'luxy.js';
import {TweenMax} from "gsap";
import {Quart, Quint, Linear} from "gsap";
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import './Directorio.css';

import NextIcon from '../images/forward-icon.svg';
import PrevIcon from '../images/backward-icon.svg';

import ControlsPage from '../components/ControlsPage';
import ControlsPageMobile from '../components/ControlsPageMobile';
import VideoPlayer from '../components/VideoPlayer';

import { isBrowser, isMobile, isTablet } from "react-device-detect";

import Quote01 from '../images/quote01.png';
import Quote02 from '../images/quote02.png';

import parse from 'html-react-parser';

class Directorio extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      is_parallax_active: true,
    };
  }

  componentDidMount = () => {
    this.setLanguage();
    window.scrollTo(0, 0);
    window.addEventListener('scroll', this.onScroll);
    window.addEventListener('resize', this.onWindowResize, true);
    this.onWindowResize();
    setTimeout(() => { 
      this.onScroll();
    }, 200);
  }

  componentWillUnmount = () => {
    window.removeEventListener('scroll', this.onScroll);
    window.removeEventListener('resize', this.onWindowResize);
  }

  componentDidUpdate() {
      
  }

  setLanguage = () => {
    const { t, i18n } = this.props;
    const lang = cookie.load('lng');
    if (lang == undefined) {
      i18n.changeLanguage('es');
      cookie.save('lng', 'es');
    } else {
      if (lang == 'es') {
        i18n.changeLanguage('es');
      } else {
        i18n.changeLanguage('en');
      }
    }
  }

  onScroll = (event) => {
    const h = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    const positionY = window.pageYOffset;
    if (positionY >= h) {
      
    } else {
      
    }
  }

  onWindowResize = () => {
    this.w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    
    if (this.w <= 576) {
      this.setState({
      });
    } else {
      this.setState({
      });
    }

    if (this.w <= 768) {
      this.setState({
        is_parallax_active: false
      });  
    } else {
      this.setState({
        is_parallax_active: true
      });  
    }
    
    if (isBrowser) {
      // Luxy.init({wrapperSpeed: 0.2});
    }
  }

  onLoadedMetadata = (event) => {
    
  }

  render () {

    const { t } = this.props;

    return (
      <>

      <div className='hero-directorio'>
        
        <div className='container-fluid hero-container'>
            <div className='row hero'>
              <span className='piedeimagen'>{t('EL.PALACIO.DE.HIERRO.POLANCO')}</span>
            </div>
        </div>

      </div>

      <div className='directorio' id="luxy">
        <div style={{ background: '#fff' }}>

            <div className='texto-lateral-seccion07'>{t('07.texto.lat')}</div>

            <div className='container'>
                <div className='row section-01'>
                    <div className='col-lg-6 col-uno'>
                        <h1 className='h-pag'>
                          {parse(t('07.h1'))}
                        </h1>
                    </div>
                    <div className='col-lg-6 col-dos'>
                        
                    </div>
                </div>
            </div>

            <div className='container'>
                <div className='row section-02'>
                    
                    <div className='col-lg-12 col-cero'>
                        <p className='titulo'>
                            <a href='https://www.elpalaciodehierro.com/'>elpalaciodehierro<span className='amarillo'>.com</span></a>
                        </p>
                    </div>

                    <div className='col-lg-6 col-uno'>
                        <p className='item'>
                            <span>EL PALACIO DE HIERRO CENTRO</span>
                            <span>Av. 20 de Noviembre no. 3,<br/>
                            Ciudad de México, 06060</span>
                        </p>

                        <p className='item'>
                            <span>EL PALACIO DE HIERRO COYOACÁN</span>
                            <span>Centro Coyoacán<br/>
                            Av. Coyoacán no. 2000,<br/>
                            Ciudad de México, 03330</span>
                        </p>

                        <p className='item'>
                            <span>EL PALACIO DE HIERRO DURANGO</span>
                            <span>Durango No. 230,<br/>
                            Ciudad de México, 06700</span>
                        </p>

                        <p className='item'>
                            <span>EL PALACIO DE HIERRO INTERLOMAS</span>
                            <span>Centro Comercial Paseo Interlomas<br/>
                            Av. de la Barranca no. 6,<br/>
                            Edo. de México, 52787</span>
                        </p>
                        
                        <p className='item'>
                            <span>EL PALACIO DE HIERRO PERISUR</span>
                            <span>Centro Comercial Perisur<br/>
                            Periférico Sur no. 4690,<br/>
                            Ciudad de México, 04500</span>
                        </p>

                        <p className='item'>
                            <span>EL PALACIO DE HIERRO POLANCO</span>
                            <span>Moliere no. 222,<br/>
                            Ciudad de México, 11570</span>
                        </p>

                        <p className='item'>
                            <span>EL PALACIO DE HIERRO SANTA FE</span>
                            <span>Centro Comercial Santa Fe<br/>
                            Av. Vasco de Quiroga no. 3800,<br/>
                            Ciudad de México, 05109</span>
                        </p>

                        <p className='item'>
                            <span>EL PALACIO DE HIERRO SATÉLITE</span>
                            <span>Cto. Centro Comercial no. 2251,<br/>
                            Cd. Satélite,<br/>
                            Edo. de México, 05310</span>
                        </p>

                        <p className='item'>
                            <span>EL PALACIO DE HIERRO GUADALAJARA</span>
                            <span>Centro Comercial Andares<br/>
                            Av. Patria no. 2085,<br/>
                            Zapopan, Jalisco, 45116</span>
                        </p>

                        <p className='item'>
                            <span>EL PALACIO DE HIERRO QUERÉTARO</span>
                            <span>Centro Comercial Antea<br/>
                            Carretera Querétaro-San Luis Potosí<br/>
                            no. 12401,<br/>
                            Querétaro, 76127</span>
                        </p>
                    </div>

                    <div className='col-lg-6 col-dos'>                  
                        <p className='item'>
                            <span>EL PALACIO DE HIERRO PUEBLA</span>
                            <span>Centro Comercial Angelópolis<br/>
                            Blvd. del Niño Poblano no. 2510,<br/>
                            Puebla, 72450</span>
                        </p>

                        <p className='item'>
                            <span>EL PALACIO DE HIERRO MONTERREY</span>
                            <span>Centro Comercial Paseo San Pedro<br/>
                            Av. José Vasconcelos no. 402,<br/>
                            San Pedro Garza García, Nuevo León, 66220</span>
                        </p>

                        <p className='item'>
                            <span>EL PALACIO DE HIERRO VERACRUZ</span>
                            <span>Centro Comercial Andamar<br/>
                            Boulevard Adolfo Ruiz Cortínez no. 4300,<br/>
                            zona Hotelera,<br/>
                            Boca del Río, Veracruz, 94299</span>
                        </p>

                        <p className='item'>
                            <span>EL PALACIO DE HIERRO VILLAHERMOSA</span>
                            <span>Centro Comercial Plaza Altabrisa<br/>
                            Anillo Periférico Carlos Pellicer<br/>
                            Cámara no. 129,<br/>
                            Villahermosa, Tabasco, 86190</span>
                        </p>

                        <p className='item'>
                            <span>LA BOUTIQUE PALACIO ACAPULCO</span>
                            <span>La Isla Shopping Village<br/>
                            Blvd. de las Naciones no. 1813,<br/>
                            Acapulco, Guerrero, 39760</span>
                        </p>

                        <p className='item'>
                            <span>LA BOUTIQUE PALACIO CANCÚN</span>
                            <span>Fashion Harbour at La Isla<br/>
                            Blvd. Kukulcán km 12.5,<br/>
                            Cancún, Quintana Roo, 77500</span>
                        </p>

                        <p className='item'>
                            <span>LA BOUTIQUE PALACIO ACOXPA</span>
                            <span>Centro Comercial Paseo Acoxpa<br/>
                            Calzada Acoxpa no. 430,<br/>
                            Ciudad de México, 14300</span>
                        </p>

                        <p className='item'>
                            <span>CASA PALACIO ANTARA</span>
                            <span>Centro Comercial Antara<br/>
                            Av. Ejército Nacional no. 843,<br/>
                            Ciudad de México, 11520</span>
                        </p>

                        <p className='item'>
                            <span>CASA PALACIO SANTA FE</span>
                            <span>Centro Comercial Santa Fe<br/>
                            Av. Vasco de Quiroga no. 3800,<br/>
                            Ciudad de México, 05109</span>
                        </p>
                    </div>
                
                </div>
            </div>

            <ControlsPage prev={this.props.prev} next={this.props.next}></ControlsPage>

        </div>
      </div>

      <ControlsPageMobile prev={this.props.prev} next={this.props.next}></ControlsPageMobile>

      </>
    );
  }
}

export default withTranslation()(Directorio);