import React from 'react';
import cookie from 'react-cookies';
import { withTranslation } from 'react-i18next';
import Luxy from 'luxy.js';
import {TweenMax} from "gsap";
import {Quart, Quint, Linear} from "gsap";
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import './Logistica.css';

import NextIcon from '../images/forward-icon.svg';
import PrevIcon from '../images/backward-icon.svg';

import ControlsPage from '../components/ControlsPage';
import ControlsPageMobile from '../components/ControlsPageMobile';
import VideoPlayer from '../components/VideoPlayer';

import { isBrowser, isMobile, isTablet } from "react-device-detect";

import Quote01 from '../images/quote01.png';
import Quote02 from '../images/quote02.png';

import GalleryItem01 from '../images/04-galeria-01-perisur@2x.jpg';
import GalleryItem02 from '../images/04-galeria-02-perisur@2x.jpg';
import GalleryItem03 from '../images/04-galeria-03-santa-fe@2x.jpg';
import GalleryItem04 from '../images/04-galeria-04-cantina-palacio@2x.jpg';
import GalleryItem05 from '../images/04-galeria-05-restaurante-palacio@2x.jpg';

import GalleryItemMobile01 from '../images/04-galeria-01-perisur-mobile@2x.jpg';
import GalleryItemMobile02 from '../images/04-galeria-02-perisur-mobile@2x.jpg';
import GalleryItemMobile03 from '../images/04-galeria-03-santa-fe-mobile@2x.jpg';
import GalleryItemMobile04 from '../images/04-galeria-04-cantina-palacio-mobile@2x.jpg';
import GalleryItemMobile05 from '../images/04-galeria-05-restaurante-palacio-mobile@2x.jpg';

import Video01 from '../video/video-perisur.mp4';
import Poster01 from '../video/video-perisur.jpg';

import parse from 'html-react-parser';

class Logistica extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      src_banner_01: '',
      is_parallax_active: true,
    };

    this.slider01Ref = React.createRef();
  }

  componentDidMount = () => {
    this.setLanguage();
    window.scrollTo(0, 0);
    window.addEventListener('scroll', this.onScroll);
    window.addEventListener('resize', this.onWindowResize, true);
    this.onWindowResize();
    setTimeout(() => { 
      this.onScroll();
    }, 200);
  }

  componentWillUnmount = () => {
    window.removeEventListener('scroll', this.onScroll);
    window.removeEventListener('resize', this.onWindowResize);
  }

  componentDidUpdate() {
      
  }

  setLanguage = () => {
    const { t, i18n } = this.props;
    const lang = cookie.load('lng');
    if (lang == undefined) {
      i18n.changeLanguage('es');
      cookie.save('lng', 'es');
    } else {
      if (lang == 'es') {
        i18n.changeLanguage('es');
      } else {
        i18n.changeLanguage('en');
      }
    }
  }

  onScroll = (event) => {
    const h = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    const positionY = window.pageYOffset;
    if (positionY >= h) {
      
    } else {
      
    }
  }

  onWindowResize = () => {
    this.w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    
    if (this.w <= 576) {
      this.setState({
        // src_banner_01: Banner01SantaFeMobile,
      });
    } else {
      this.setState({
        // src_banner_01: Banner01SantaFe,
      });
    }

    if (this.w <= 768) {
      this.setState({
        is_parallax_active: false
      });  
    } else {
      this.setState({
        is_parallax_active: true
      });  
    }
    
    if (isBrowser) {
      // Luxy.init({wrapperSpeed: 0.2});
    }
  }

  onLoadedMetadata = (event) => {
    
  }

  nextFn = (event) => {
    event.preventDefault();
    this.slider01Ref.current.slideNext();
  }

  prevFn = (event) => {
    event.preventDefault();
    this.slider01Ref.current.slidePrev();
  }

  render () {

    const { t } = this.props;

    const responsive = {
      0: { items: 1 },
      568: { items: 1 },
      1024: { items: 1 },
    };

    const items = [
        <div className="item" data-value="1">
          <img src={(isBrowser || isTablet) ? GalleryItem01 : GalleryItemMobile01} />
          <span className='piedeimagen-carousel'>{t('EL.PALACIO.DE.HIERRO.PERISUR')}</span>
        </div>,
        <div className="item" data-value="2">
          <img src={(isBrowser || isTablet) ? GalleryItem02 : GalleryItemMobile02} />
          <span className='piedeimagen-carousel'>{t('EL.PALACIO.DE.HIERRO.PERISUR')}</span>
        </div>,
        <div className="item" data-value="3">
          <img src={(isBrowser || isTablet) ? GalleryItem03 : GalleryItemMobile03} />
          <span className='piedeimagen-carousel'>{t('EL.PALACIO.DE.HIERRO.SANTA.FE')}</span>
        </div>,
        <div className="item" data-value="4">
          <img src={(isBrowser || isTablet) ? GalleryItem04 : GalleryItemMobile04} />
          <span className='piedeimagen-carousel'>{t('LA.CANTINA.PALACIO')}</span>
        </div>,
        <div className="item" data-value="5">
          <img src={(isBrowser || isTablet) ? GalleryItem05 : GalleryItemMobile05} />
          <span className='piedeimagen-carousel'>{t('EL.PALACIO.DE.HIERRO.SANTA.FE')}</span>
        </div>,
    ];

    return (
      <>

      <div className='hero-logistica'>
        
        <div className='container-fluid hero-container'>
            <div className='row hero'>
                <span className='piedeimagen'>{t('EL.PALACIO.DE.HIERRO.POLANCO')}</span>
            </div>
        </div>

      </div>

      <div className='logistica' id="luxy">
        <div style={{ background: '#fff' }}>

            <div className='texto-lateral-seccion04'>{t('04.texto.lat')}</div>

            <div className='container'>
                <div className='row section-01'>
                    <div className='col-lg-6 col-uno'>
                        <h1 className='h-pag'>
                          {parse(t('04.h1'))}
                        </h1>
                    </div>
                    <div className='col-lg-6 col-dos'>
                        
                    </div>
                </div>
            </div>

            <div className='container'>
                <div className='row section-02'>

                    <div className='col-lg-6 col-dos'>
                      {parse(t('04.s2.text1'))}
                    </div>

                    <div className='col-lg-6 col-uno'>
                        <p className="quoted">
                            <span className='quote01'><img src={Quote01}></img></span>
                            {parse(t('04.s2.quote'))}
                            <span className='quote02'><img src={Quote02}></img></span>
                        </p>
                    </div>
                
                </div>
            </div>

            <div className='container'>
                <div className='row section-03'>
                    <div className='col-lg-12 col-uno titulo-renovacion'>
                        <span className='line'></span>
                        <span className='texto'>{t('04.s3.title')}</span>
                    </div>
                </div>
            </div>

            <div className='container'>
                <div className='row section-04'>
                <div className='col-lg-12 col-uno'>
                    <VideoPlayer poster={Poster01} video={Video01}></VideoPlayer>
                </div>
                </div>
            </div>

            <div className='container-fluid'>
                <div className='row section-06'>
                    <div className='col-lg-12 col-uno'>

                        <AliceCarousel
                            ref={this.slider01Ref}
                            infinite={true}
                            paddingRight={40}
                            mouseTracking
                            items={items}
                            responsive={responsive}
                            disableDotsControls={true}
                            disableButtonsControls={true}
                        />

                        <a href='#' className='prev' onClick={this.prevFn}>
                            <img src={PrevIcon}></img>
                        </a>
                        <a href='#' className='next' onClick={this.nextFn}>
                            <img src={NextIcon}></img>
                        </a>

                    </div>
                </div>
            </div>

            <ControlsPage prev={this.props.prev} next={this.props.next}></ControlsPage>

        </div>
      </div>

      <ControlsPageMobile prev={this.props.prev} next={this.props.next}></ControlsPageMobile>

      </>
    );
  }
}

export default withTranslation()(Logistica);