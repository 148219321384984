import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Inicio from './pages/Inicio';
import InformeConsejoAsamblea from './pages/InformeConsejoAsamblea';
import GrupoPalacioDeHierro from './pages/GrupoPalacioDeHierro';
import DivisionComercial from './pages/DivisionComercial';
import Logistica from './pages/Logistica';
import Finanzas from './pages/Finanzas';
import ConsejoDeAdministracion from './pages/ConsejoDeAdministracion';
import Directorio from './pages/Directorio';
import InformeFinanciero from './pages/InformeFinanciero';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      w: window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
    };
  }
  
  componentDidMount() {
    window.addEventListener('resize', this.onWindowResize, true);
  }

  onWindowResize = () => {
    this.setState({
      w: window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
    });
  }

  openNavbar = (toggle) => {
    console.log(toggle);
  }
  
  render() {
    return (
      <>
        <Router>
          <div className='wrapper'>
            <Switch>
              <Route path={`${process.env.PUBLIC_URL}/`} exact component={Inicio} />
              <Route path={`${process.env.PUBLIC_URL}/inicio`} component={Inicio} />
              <Route path={`${process.env.PUBLIC_URL}/informe-del-consejo-a-la-asamblea`} render={(props) => (<InformeConsejoAsamblea {...props} prev={`${process.env.PUBLIC_URL}/`} next={`${process.env.PUBLIC_URL}/grupo-palacio-de-hierro`} />)} />
              <Route path={`${process.env.PUBLIC_URL}/grupo-palacio-de-hierro`} render={(props) => (<GrupoPalacioDeHierro {...props} prev={`${process.env.PUBLIC_URL}/informe-del-consejo-a-la-asamblea`} next={`${process.env.PUBLIC_URL}/division-comercial`} />)} />
              <Route path={`${process.env.PUBLIC_URL}/division-comercial`} render={(props) => (<DivisionComercial {...props} prev={`${process.env.PUBLIC_URL}/grupo-palacio-de-hierro`} next={`${process.env.PUBLIC_URL}/logistica`} />)} />
              <Route path={`${process.env.PUBLIC_URL}/logistica`} render={(props) => (<Logistica {...props} prev={`${process.env.PUBLIC_URL}/division-comercial`} next={`${process.env.PUBLIC_URL}/finanzas`} />)} />
              <Route path={`${process.env.PUBLIC_URL}/finanzas`} render={(props) => (<Finanzas {...props} prev={`${process.env.PUBLIC_URL}/logistica`} next={`${process.env.PUBLIC_URL}/consejo-de-administracion`} />)} />
              <Route path={`${process.env.PUBLIC_URL}/consejo-de-administracion`} render={(props) => (<ConsejoDeAdministracion {...props} prev={`${process.env.PUBLIC_URL}/finanzas`} next={`${process.env.PUBLIC_URL}/directorio`} />)} />
              <Route path={`${process.env.PUBLIC_URL}/directorio`} render={(props) => (<Directorio {...props} prev={`${process.env.PUBLIC_URL}/consejo-de-administracion`} next={`${process.env.PUBLIC_URL}/informe-financiero`} />)} />
              <Route path={`${process.env.PUBLIC_URL}/informe-financiero`} render={(props) => (<InformeFinanciero {...props} prev={`${process.env.PUBLIC_URL}/directorio`} next='#' />)} />
            </Switch>
          </div>
          <Navbar opennavbar={this.openNavbar} />
          {/* <div style={{ position: 'fixed', bottom: '0', left: '0', color: '#000', padding: '5px', background: '#fff', fontSize: '12px' }}>
            {this.state.w}
          </div> */}
        </Router>
      </>
    );
  }
}

export default App;