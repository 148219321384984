import React from 'react';

class Num extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
        num: this.props.num,
    };
  }

  componentDidMount = () => {

  }

  componentWillUnmount = () => {

  }

  componentDidUpdate() {
      
  }

  animate = () => {
    // if (this.state.num <= this.props.num) {
    //     setTimeout(() => {
    //       this.setState({
    //           num: this.state.num + 100, 
    //       });
    //       this.animate();
    //     }, 1);
    //   }
  }

  render () {
    return (
        <span className='num'>
            {this.state.num}
        </span>
    );
  }
}

export default Num;