import React from 'react';
import { withTranslation } from 'react-i18next';
import './ControlsPageMobile.css';
import NextIconW from '../images/forward-w-icon.svg';
import PrevIconW from '../images/backward-w-icon.svg';

class ControlsPageMobile extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  nextFn = (event) => {
    event.preventDefault();
  }

  prevFn = (event) => {
    event.preventDefault();
  }

  render () {

    const { t } = this.props;

    return (
        <div className='container-fluid controls-page-mobile'>
            <div className='row'>
                <div className='col-lg-12 col-uno'>
                    <a href={this.props.prev} className='prev-page'>
                        <img src={PrevIconW}></img>
                        <span>{t('controls.anterior')}</span>
                    </a>
                    <a href={this.props.next} className='next-page' style={{ display: (this.props.next == '#') ? 'none' : 'block' }}>
                        <span>{t('controls.siguiente')}</span>
                        <img src={NextIconW}></img>
                    </a>
                </div>
            </div>
        </div>
    );
  }
}

export default withTranslation()(ControlsPageMobile);