import React from 'react';
import cookie from 'react-cookies';
import { withTranslation } from 'react-i18next';
import Luxy from 'luxy.js';
import {TweenMax} from "gsap";
import {Quart, Quint, Linear} from "gsap";
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import './ConsejoDeAdministracion.css';

import NextIcon from '../images/forward-icon.svg';
import PrevIcon from '../images/backward-icon.svg';

import ControlsPage from '../components/ControlsPage';
import ControlsPageMobile from '../components/ControlsPageMobile';
import VideoPlayer from '../components/VideoPlayer';

import { isBrowser, isMobile, isTablet } from "react-device-detect";

import Quote01 from '../images/quote01.png';
import Quote02 from '../images/quote02.png';

import parse from 'html-react-parser';

class ConsejoDeAdministracion extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      is_parallax_active: true,
    };
  }

  componentDidMount = () => {
    this.setLanguage();
    window.scrollTo(0, 0);
    window.addEventListener('scroll', this.onScroll);
    window.addEventListener('resize', this.onWindowResize, true);
    this.onWindowResize();
    setTimeout(() => { 
      this.onScroll();
    }, 200);
  }

  componentWillUnmount = () => {
    window.removeEventListener('scroll', this.onScroll);
    window.removeEventListener('resize', this.onWindowResize);
  }

  componentDidUpdate() {
      
  }

  setLanguage = () => {
    const { t, i18n } = this.props;
    const lang = cookie.load('lng');
    if (lang == undefined) {
      i18n.changeLanguage('es');
      cookie.save('lng', 'es');
    } else {
      if (lang == 'es') {
        i18n.changeLanguage('es');
      } else {
        i18n.changeLanguage('en');
      }
    }
  }

  onScroll = (event) => {
    const h = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    const positionY = window.pageYOffset;
    if (positionY >= h) {
      
    } else {
      
    }
  }

  onWindowResize = () => {
    this.w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    
    if (this.w <= 576) {
      this.setState({
      });
    } else {
      this.setState({
      });
    }

    if (this.w <= 768) {
      this.setState({
        is_parallax_active: false
      });  
    } else {
      this.setState({
        is_parallax_active: true
      });  
    }
    
    if (isBrowser) {
      // Luxy.init({wrapperSpeed: 0.2});
    }
  }

  onLoadedMetadata = (event) => {
    
  }

  render () {

    const { t } = this.props;

    return (
      <>

      <div className='hero-consejo-de-administracion'>
        
        <div className='container-fluid hero-container'>
            <div className='row hero'>
            </div>
        </div>

      </div>

      <div className='consejo-de-administracion' id="luxy">
        <div style={{ background: '#fff' }}>

            <div className='texto-lateral-seccion06'>{t('06.texto.lat')}</div>

            <div className='container'>
                <div className='row section-01'>
                    <div className='col-lg-6 col-uno'>
                        <h1 className='h-pag'>
                          {parse(t('06.h1'))}
                        </h1>
                    </div>
                    <div className='col-lg-6 col-dos'>
                        
                    </div>
                </div>
            </div>

            <div className='container'>
                <div className='row section-02'>
                    
                    <div className='col-lg-12 col-cero'>
                        <p className='titulo'>{t('06.presidente')}</p>
                        <p className='titulo'>{t('06.presidente.name')}</p>
                    </div>

                    <div className='col-lg-6 col-uno'>
                        <p className='titulo'>{t('CONSEJEROS.PROPIETARIOS')}</p> 
                        
                        <p className='item'>
                        {parse(t('06.s2.item1'))}
                        </p>

                        <p className='item'>
                        {parse(t('06.s2.item2'))}
                        </p>

                        <p className='item'>
                        {parse(t('06.s2.item3'))}
                        </p>

                        <p className='item'>
                        {parse(t('06.s2.item4'))}
                        </p>

                        <p className='item'>
                        {parse(t('06.s2.item5'))}
                        </p>

                        <p className='item'>
                        {parse(t('06.s2.item6'))}
                        </p>

                        <p className='item'>
                        {parse(t('06.s2.item7'))}
                        </p>

                        <p className='item'>
                        {parse(t('06.s2.item8'))}
                        </p>

                        <p className='item'>
                        {parse(t('06.s2.item9'))}
                        </p>

                        <p className='item'>
                        {parse(t('06.s2.item10'))}
                        </p>

                        <p className='item'>
                        {parse(t('06.s2.item11'))}
                        </p>

                        <p className='item'>
                        {parse(t('06.s2.item12'))}
                        </p>

                        <p className='item'>
                        {parse(t('06.s2.item13'))}
                        </p>

                        <p className='item'>
                        {parse(t('06.s2.item14'))}
                        </p>

                        <p className='item'>
                        {parse(t('06.s2.item15'))}
                        </p>
                    </div>

                    <div className='col-lg-6 col-dos'>
                        <p className='titulo'>{t('CONSEJEROS.SUPLENTES')}</p> 
                        
                        <p className='item'>
                        {parse(t('06.s2.item16'))}
                        </p>

                        <p className='item'>
                        {parse(t('06.s2.item17'))}
                        </p>

                        <p className='item'>
                        {parse(t('06.s2.item18'))}
                        </p>

                        <p className='item'>
                        {parse(t('06.s2.item19'))}
                        </p>

                        <p className='item'>
                        {parse(t('06.s2.item20'))}
                        </p>

                        <p className='item'>
                        {parse(t('06.s2.item21'))}
                        </p>

                        <p className='item'>
                        {parse(t('06.s2.item22'))}
                        </p>

                        <p className='item'>
                        {parse(t('06.s2.item23'))}
                        </p>

                        <p className='item'>
                        {parse(t('06.s2.item24'))}
                        </p>

                        <p className='item'>
                        {parse(t('06.s2.item25'))}
                        </p>

                        <p className='item'>
                        {parse(t('06.s2.item26'))}
                        </p>

                        <p className='item'>
                        {parse(t('06.s2.item27'))}
                        </p>

                        <p className='item'>
                        {parse(t('06.s2.item28'))}
                        </p>
                    </div>
                
                </div>
            </div>


            <div className='container'>
                <div className='row section-04'>
                    
                    <div className='col-lg-12 col-cero'>
                        <p className='titulo'>{t('06.presidente')}</p>
                        <p className='titulo'>{t('06.presidente.name')}</p>
                    </div>

                    <div className='col-lg-6 col-uno'>
                      <p className='titulo'>{t('CONSEJEROS.PROPIETARIOS')}</p> 
                    </div>

                    <div className='col-lg-6 col-uno'>
                      <p className='titulo'>{t('CONSEJEROS.SUPLENTES')}</p> 
                    </div>

                </div>
            </div>

            <div className='container'>
                <div className='row section-05'>

                    <div className='col-lg-6 col-uno'>
                      <p className='item'>
                      {parse(t('06.s2.item1'))}
                      </p>
                    </div>

                    <div className='col-lg-6 col-uno'>
                      <p className='item'>
                      {parse(t('06.s2.item16'))}
                      </p>
                    </div>

                </div>
            </div>

            <div className='container'>
                <div className='row section-05'>

                    <div className='col-lg-6 col-uno'>
                      <p className='item'>
                      {parse(t('06.s2.item2'))}
                      </p>
                    </div>

                    <div className='col-lg-6 col-uno'>
                      <p className='item'>
                      {parse(t('06.s2.item17'))}
                      </p>
                    </div>

                </div>
            </div>

            <div className='container'>
                <div className='row section-05'>

                    <div className='col-lg-6 col-uno'>
                      <p className='item'>
                      {parse(t('06.s2.item3'))}
                      </p>
                    </div>

                    <div className='col-lg-6 col-uno'>
                      <p className='item'>
                      {parse(t('06.s2.item18'))}
                      </p>
                    </div>

                </div>
            </div>

            <div className='container'>
                <div className='row section-05'>

                    <div className='col-lg-6 col-uno'>
                      <p className='item'>
                      {parse(t('06.s2.item4'))}
                      </p>
                    </div>

                    <div className='col-lg-6 col-uno'>
                      <p className='item'>
                      {parse(t('06.s2.item19'))}
                      </p>
                    </div>

                </div>
            </div>

            <div className='container'>
                <div className='row section-05'>

                    <div className='col-lg-6 col-uno'>
                      <p className='item'>
                      {parse(t('06.s2.item5'))}
                      </p>
                    </div>

                    <div className='col-lg-6 col-uno'>
                      <p className='item'>
                      {parse(t('06.s2.item20'))}
                      </p>
                    </div>

                </div>
            </div>

            <div className='container'>
                <div className='row section-05'>

                    <div className='col-lg-6 col-uno'>
                      <p className='item'>
                      {parse(t('06.s2.item6'))}
                      </p>
                    </div>

                    <div className='col-lg-6 col-uno'>
                      <p className='item'>
                      {parse(t('06.s2.item21'))}
                      </p>
                    </div>

                </div>
            </div>

            <div className='container'>
                <div className='row section-05'>

                    <div className='col-lg-6 col-uno'>
                      <p className='item'>
                      {parse(t('06.s2.item7'))}
                      </p>
                    </div>

                    <div className='col-lg-6 col-uno'>
                      <p className='item'>
                      {parse(t('06.s2.item22'))}
                      </p>
                    </div>

                </div>
            </div>

            <div className='container'>
                <div className='row section-05'>

                    <div className='col-lg-6 col-uno'>
                      <p className='item'>
                      {parse(t('06.s2.item8'))}
                      </p>
                    </div>

                    <div className='col-lg-6 col-uno'>
                      <p className='item'>
                      {parse(t('06.s2.item23'))}
                      </p>
                    </div>

                </div>
            </div>

            <div className='container'>
                <div className='row section-05'>

                    <div className='col-lg-6 col-uno'>
                      <p className='item'>
                      {parse(t('06.s2.item9'))}
                      </p>
                    </div>

                </div>
            </div>

            <div className='container'>
                <div className='row section-05'>

                    <div className='col-lg-6 col-uno'>
                      <p className='item'>
                      {parse(t('06.s2.item10'))}
                      </p>
                    </div>

                    <div className='col-lg-6 col-uno'>
                      <p className='item'>
                      {parse(t('06.s2.item24'))}
                      </p>
                    </div>

                </div>
            </div>

            <div className='container'>
                <div className='row section-05'>

                    <div className='col-lg-6 col-uno'>
                      <p className='item'>
                      {parse(t('06.s2.item11'))}
                      </p>
                    </div>

                    <div className='col-lg-6 col-uno'>
                      <p className='item'>
                      {parse(t('06.s2.item25'))}
                      </p>
                    </div>

                </div>
            </div>

            <div className='container'>
                <div className='row section-05'>

                    <div className='col-lg-6 col-uno'>
                      <p className='item'>
                      {parse(t('06.s2.item12'))}
                      </p>
                    </div>

                    <div className='col-lg-6 col-uno'>
                      <p className='item'>
                      {parse(t('06.s2.item26'))}
                      </p>
                    </div>

                </div>
            </div>

            <div className='container'>
                <div className='row section-05'>

                    <div className='col-lg-6 col-uno'>
                      <p className='item'>
                      {parse(t('06.s2.item13'))}
                      </p>
                    </div>

                    <div className='col-lg-6 col-uno'>
                      <p className='item'>
                      {parse(t('06.s2.item27'))}
                      </p>
                    </div>

                </div>
            </div>

            <div className='container'>
                <div className='row section-05'>

                    <div className='col-lg-6 col-uno'>
                      <p className='item'>
                      {parse(t('06.s2.item14'))}
                      </p>
                    </div>

                    <div className='col-lg-6 col-uno'>
                      <p className='item'>
                      {parse(t('06.s2.item28'))}
                      </p>
                    </div>

                </div>
            </div>

            <div className='container'>
                <div className='row section-05 section-05-last'>

                    <div className='col-lg-6 col-uno'>
                      <p className='item'>
                      {parse(t('06.s2.item15'))}
                      </p>
                    </div>

                </div>
            </div>

            <div className='container'>
                <div className='row section-03'>
                    <div className='col-lg-12 col-uno'>
                        {parse(t('06.s3.text1'))}
                    </div>
                </div>
            </div>

            <ControlsPage prev={this.props.prev} next={this.props.next}></ControlsPage>

        </div>
      </div>

      <ControlsPageMobile prev={this.props.prev} next={this.props.next}></ControlsPageMobile>

      </>
    );
  }
}

export default withTranslation()(ConsejoDeAdministracion);