import React from 'react';
import cookie from 'react-cookies';
import { withTranslation } from 'react-i18next';
import Luxy from 'luxy.js';
import {TweenMax} from "gsap";
import {Quart, Quint, Linear} from "gsap";
import AliceCarousel from 'react-alice-carousel';
import CountUp from 'react-countup';
import 'react-alice-carousel/lib/alice-carousel.css';
import './GrupoPalacioDeHierro.css';

import NextIcon from '../images/forward-icon.svg';
import PrevIcon from '../images/backward-icon.svg';

import ControlsPage from '../components/ControlsPage';
import ControlsPageMobile from '../components/ControlsPageMobile';
import VideoPlayer from '../components/VideoPlayer';

import { isBrowser, isMobile, isTablet } from "react-device-detect";

import GalleryItem01 from '../images/01-galeria-04-suarez@2x.jpg';
import GalleryItem02 from '../images/02-1-galeria-02-santa-fe@2x.jpg';
import GalleryItem03 from '../images/02-1-galeria-03-saint-laurent@2x.jpg';
import GalleryItem04 from '../images/02-1-galeria-04-messika@2x.jpg';
import GalleryItem05 from '../images/02-1-galeria-05-perisur@2x.jpg';

import GalleryItemMobile01 from '../images/01-galeria-04-suarez-mobile@2x.jpg';
import GalleryItemMobile02 from '../images/02-1-galeria-02-santa-fe-mobile@2x.jpg';
import GalleryItemMobile03 from '../images/02-1-galeria-03-saint-laurent-mobile@2x.jpg';
import GalleryItemMobile04 from '../images/02-1-galeria-04-messika-mobile@2x.jpg';
import GalleryItemMobile05 from '../images/02-1-galeria-05-perisur-mobile@2x.jpg';

import Gallery02Item01 from '../images/02-2-galeria-01-salon-de-escritura@2x.jpg';
import Gallery02Item02 from '../images/02-2-galeria-02-zegna@2x.jpg';
import Gallery02Item03 from '../images/02-2-galeria-03-concierge@2x.jpg';
import Gallery02Item04 from '../images/02-2-galeria-04-perisur@2x.jpg';

import Gallery02ItemMobile01 from '../images/02-2-galeria-01-salon-de-escritura-mobile@2x.jpg';
import Gallery02ItemMobile02 from '../images/02-2-galeria-02-zegna-mobile@2x.jpg';
import Gallery02ItemMobile03 from '../images/02-2-galeria-03-concierge-mobile@2x.jpg';
import Gallery02ItemMobile04 from '../images/02-2-galeria-04-perisur-mobile@2x.jpg';

import Banner01Polanco from '../images/02-banner-01-polanco@2x.jpg';
import Banner01PolancoMobile from '../images/02-banner-01-polanco-mobile@2x.jpg';

import Num from '../components/Num';

import CarolinaHerrarBanner from '../images/02-quote-01-carolina-herrera@2x.jpg';

import PalacioDeHierroStaFe from '../images/02-banner-02-santa-fe@2x.jpg';
import PalacioDeHierroStaFeMobile from '../images/02-banner-02-santa-fe-mobile@2x.jpg';

import PalacioDeHierroPerisur from '../images/02-banner-03-perisur@2x.jpg';
import PalacioDeHierroPerisurMobile from '../images/02-banner-03-perisur-mobile@2x.jpg';

import PalacioDeHierroPerisur02 from '../images/02-banner-04-perisur@2x.jpg';
import PalacioDeHierroPerisur02Mobile from '../images/02-banner-04-perisur-mobile@2x.jpg';

import BannerReabrimos from '../images/02-banner-reabrimos-background@2x.jpg';
import BannerReabrimosMobile from '../images/02-banner-reabrimos-background-mobile@2x.jpg';

import BannerStaFe from '../images/02-banner-05-santa-fe@2x.jpg';
import BannerStaFeMobile from '../images/02-banner-05-santa-fe-mobile@2x.jpg';

import AromariaBanner from '../images/02-quote-02-aromaria@2x.jpg';

import BannerStaFe03 from '../images/02-banner-06-santa-fe@2x.jpg'
import BannerStaFe03Mobile from '../images/02-banner-06-santa-fe-mobile@2x.jpg';

import TiffanyVideo from '../video/video-navidad_2020.mp4';
import TiffanyPoster from '../images/video-navidad-2020@2x.jpg';

import ReaperturaVideo from '../video/ESTAMOS_TOTALMENTE_CONTIGO_1080p.mp4';
import ReaperturaPoster from '../video/ESTAMOS_TOTALMENTE_CONTIGO_1080p.jpg';

import Grafica from '../images/grafica.svg';
// import Grafica from '../components/Grafica';

import Quote01 from '../images/quote01.png';
import Quote02 from '../images/quote02.png';

import parse from 'html-react-parser';



class GrupoPalacioDeHierro extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      src_banner_01: '',
      src_banner_02: '',
      src_banner_03: '',
      src_banner_04: '',
      src_banner_05: '',
      src_banner_06: '',
      is_parallax_active: true,
    };

    this.slider01Ref = React.createRef();
    this.slider02Ref = React.createRef();
    this.barra01Ref = null;
    this.barra02Ref = null;
    this.barra03Ref = null;
    this.barrasRef = React.createRef();
    this.alreadyAnimBarras = false;
    this.alreadyAnimNums = false;
    
    this.num01Ref = React.createRef();
    this.num02Ref = React.createRef();
    this.num03Ref = React.createRef();

    this.seguidoresRef = React.createRef();
    this.countUp01Ref = React.createRef();
    this.countUp02Ref = React.createRef();
    this.countUp03Ref = React.createRef();
    this.countUp04Ref = React.createRef();
    this.countUp05Ref = React.createRef();
    this.countUp06Ref = React.createRef();
    this.countUp07Ref = React.createRef();
    this.countUp08Ref = React.createRef();
  }

  componentDidMount = () => {
    this.setLanguage();
    window.scrollTo(0, 0);
    window.addEventListener('scroll', this.onScroll);
    window.addEventListener('resize', this.onWindowResize, true);
    this.onWindowResize();
    setTimeout(() => { 
      this.onScroll();
      const element = document.getElementById(window.location.hash.replace("#", ""));
      window.scrollTo({
        behavior: element ? "smooth" : "auto",
        top: element ? element.offsetTop : 0
      });
    }, 200);
  }

  componentWillUnmount = () => {
    window.removeEventListener('scroll', this.onScroll);
    window.removeEventListener('resize', this.onWindowResize);
  }

  componentDidUpdate() {
      
  }

  setLanguage = () => {
    const { t, i18n } = this.props;
    const lang = cookie.load('lng');
    if (lang == undefined) {
      i18n.changeLanguage('es');
      cookie.save('lng', 'es');
    } else {
      if (lang == 'es') {
        i18n.changeLanguage('es');
      } else {
        i18n.changeLanguage('en');
      }
    }
  }

  onScroll = (event) => {
    const h = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    const positionY = window.pageYOffset;
    if (positionY >= h) {
      
    } else {
      
    }

    var topBarras =  this.barrasRef.current.getBoundingClientRect().top;
    if (topBarras < (h / 2) + 200) {
      this.animBarras();
    }

    var topSeguidoresRef =  this.seguidoresRef.current.getBoundingClientRect().top;
    // console.log( topSeguidoresRef );
    if (topSeguidoresRef < (h / 2) + 200) {
      this.animNums();
      console.log('1');
    } else {
      console.log('0');
    }
  }

  animBarras = () => {
    if (this.alreadyAnimBarras == false) {
      this.alreadyAnimBarras = true;
    }
    TweenMax.to(this.barra01Ref, 1, {width: 26 + 30 + '%', ease: Quart.easeOut});
    TweenMax.to(this.barra02Ref, 1, {width: 35 + 30 + '%', ease: Quart.easeOut, delay: .1});
    TweenMax.to(this.barra03Ref, 1, {width: 34 + 30 + '%', ease: Quart.easeOut, delay: .2});
  }

  animNums = () => {
    if (this.alreadyAnimNums == false) {
      this.alreadyAnimNums = true;
    }
    this.countUp01Ref.current.start();
    this.countUp02Ref.current.start();
    this.countUp03Ref.current.start();
    this.countUp04Ref.current.start();
    this.countUp05Ref.current.start();
    this.countUp06Ref.current.start();
    this.countUp07Ref.current.start();
    this.countUp08Ref.current.start();
  }

  // animNums = () => {
  //   this.num01Ref.current.animate();
  //   setTimeout(() => {
  //     this.num02Ref.current.animate();
  //   }, 100);
  //   setTimeout(() => {
  //     this.num03Ref.current.animate();
  //   }, 200);
  // }

  onWindowResize = () => {
    this.w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    
    if (this.w <= 576) {
      this.setState({
        src_banner_01: Banner01PolancoMobile,
        src_banner_02: PalacioDeHierroStaFeMobile,
        src_banner_03: PalacioDeHierroPerisurMobile,
        src_banner_04: PalacioDeHierroPerisur02Mobile,
        src_banner_05: BannerStaFeMobile,
        src_banner_06: BannerStaFe03Mobile,
      });
    } else {
      this.setState({
        src_banner_01: Banner01Polanco,
        src_banner_02: PalacioDeHierroStaFe,
        src_banner_03: PalacioDeHierroPerisur,
        src_banner_04: PalacioDeHierroPerisur02,
        src_banner_05: BannerStaFe,
        src_banner_06: BannerStaFe03,
      });
    }

    if (this.w <= 768) {
      this.setState({
        is_parallax_active: false
      });  
    } else {
      this.setState({
        is_parallax_active: true
      });  
    }
    
    if (isBrowser) {
      // Luxy.init({wrapperSpeed: 0.2});
    }
  }

  onLoadedMetadata = (event) => {
    
  }

  nextFn = (event) => {
    event.preventDefault();
    this.slider01Ref.current.slideNext();
  }

  prevFn = (event) => {
    event.preventDefault();
    this.slider01Ref.current.slidePrev();
  }

  nextFn02 = (event) => {
    event.preventDefault();
    this.slider02Ref.current.slideNext();
  }

  prevFn02 = (event) => {
    event.preventDefault();
    this.slider02Ref.current.slidePrev();
  }

  onClickNumber = () => {
    // console.log( this.countUp01Ref.current.start() );
  }

  render () {

    const { t } = this.props;

    const responsive = {
      0: { items: 1 },
      568: { items: 1 },
      1024: { items: 1 },
    };
  
    const items = [
      <div className="item" data-value="1">
        <img src={(isBrowser || isTablet) ? GalleryItem01 : GalleryItemMobile01} />
        <span className='piedeimagen-carousel'>{t('EL.PALACIO.DE.HIERRO.PERISUR')}</span>
      </div>,
      // <div className="item" data-value="2">
      //   <img src={(isBrowser || isTablet) ? GalleryItem02 : GalleryItemMobile02} />
      //   <span className='piedeimagen-carousel'>EL PALACIO DE HIERRO SANTA FE</span>
      // </div>,
      <div className="item" data-value="3">
        <img src={(isBrowser || isTablet) ? GalleryItem03 : GalleryItemMobile03} />
        <span className='piedeimagen-carousel'>{t('EL.PALACIO.DE.HIERRO.PERISUR')}</span>
      </div>,
      <div className="item" data-value="4">
        <img src={(isBrowser || isTablet) ? GalleryItem04 : GalleryItemMobile04} />
        <span className='piedeimagen-carousel'>{t('EL.PALACIO.DE.HIERRO.PERISUR')}</span>
      </div>,
      <div className="item" data-value="5">
        <img src={(isBrowser || isTablet) ? GalleryItem05 : GalleryItemMobile05} />
        <span className='piedeimagen-carousel'>{t('EL.PALACIO.DE.HIERRO.PERISUR')}</span>
      </div>,
    ];

    const items02 = [
      <div className="item" data-value="1">
        <img src={(isBrowser || isTablet) ? Gallery02Item01 : Gallery02ItemMobile01} />
        <span className='piedeimagen-carousel'>{t('EL.PALACIO.DE.HIERRO.POLANCO')}</span>
      </div>,
      <div className="item" data-value="3">
        <img src={(isBrowser || isTablet) ? Gallery02Item02 : Gallery02ItemMobile02} />
        <span className='piedeimagen-carousel'>{t('EL.PALACIO.DE.HIERRO.PERISUR')}</span>
      </div>,
      <div className="item" data-value="4">
        <img src={(isBrowser || isTablet) ? Gallery02Item03 : Gallery02ItemMobile03} />
        <span className='piedeimagen-carousel'>{t('EL.PALACIO.DE.HIERRO.PERISUR')}</span>
      </div>,
      <div className="item" data-value="5">
        <img src={(isBrowser || isTablet) ? Gallery02Item04 : Gallery02ItemMobile04} />
        <span className='piedeimagen-carousel'>{t('EL.PALACIO.DE.HIERRO.PERISUR')}</span>
      </div>,
    ];

    return (
      <>

      <div className='hero-grupo-palacio'>
        
        <div className='container-fluid hero-container'>
            <div className='row hero'>
                <span className='piedeimagen'>{t('EL.PALACIO.DE.HIERRO.SANTA.FE')}</span>
            </div>
        </div>

      </div>

      <div className='grupopalacio' id="luxy">
        <div style={{ background: '#fff' }}>

          <div className='texto-lateral-seccion02'>{t('02.texto.lat')}</div>

          <div className='container'>
            <div className='row section-01'>
              <div className='col-lg-6 col-uno'>
                <h1 className='h-pag'>
                  {parse(t('02.h1'))}
                </h1>
              </div>
              <div className='col-lg-6 col-dos'>
                <p>
                  { parse(t('02.s1.p1')) }
                </p>
                <p>
                  {t('02.s1.p2')}
                </p>
              </div>
            </div>
          </div>

          <div className='container'>
            <div className='row section-03'>
              <div className='col-lg-12 col-cero'>
                <h3>
                  <span className='line'></span>
                  <span className='text'>
                    {parse(t('02.s3.title'))}
                  </span>
                </h3>
              </div>
              <div className='col-lg-6 col-uno'>
                {parse(t('02.s3.text1'))}
              </div>
              <div className='col-lg-6 col-dos'>
                
                {parse(t('02.s3.text2'))}
                
                <p className="quoted"><span className="quote01"><img src={Quote01}></img></span>
                  {parse(t('02.s3.quote'))}
                  <span className="quote02"><img src={Quote02}></img></span>
                </p>
              </div>
            </div>
          </div>

          <div className='container'>
            <div className='row section-02'>
              <div className='col-lg-6 col-uno'>
                <div className='cuadro-gris'></div>
                {parse(t('02.s2.title'))}
              </div>
              <div className='col-lg-6 col-dos'>
                <div className='contenedor'>
                  <div className='anos'>
                    <div className='ano'>2020</div>
                    <div className='ano'>2019</div>
                    <div className='ano'>2018</div>
                  </div>
                  <div className='barras' ref={this.barrasRef}>
                    <div className='barra-contenedor'>
                      <span className='barra' ref={span => this.barra01Ref = span} style={{ backgroundColor: '#fdc806' }}></span>
                      <Num num='$26,345' ref={this.num01Ref}></Num>
                    </div>
                    <div className='barra-contenedor'>
                      <span className='barra' ref={span => this.barra02Ref = span} style={{ backgroundColor: '#fddb5d' }}></span>
                      {/* <span className='num'>$35,610</span> */}
                      <Num num='$35,610' ref={this.num02Ref}></Num>
                    </div>
                    <div className='barra-contenedor'>
                      <span className='barra' ref={span => this.barra03Ref = span} style={{ backgroundColor: '#ffeca8' }}></span>
                      {/* <span className='num'>$34,702</span> */}
                      <Num num='$34,702' ref={this.num03Ref}></Num>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='container'>
            <div className='row section-23'>
              <div className='col-lg-12 col-uno titulo-renovacion'>
                <span className='line'></span>
                <span className='texto'>{t('02.s23.span')}</span>
              </div>
            </div>
          </div>

          <div className='container-fluid'>
            <div className='row section-06'>
              <div className='col-lg-12 col-uno'>

                <AliceCarousel
                  ref={this.slider01Ref}
                  infinite={true}
                  paddingRight={40}
                  mouseTracking
                  items={items}
                  responsive={responsive}
                  disableDotsControls={true}
                  disableButtonsControls={true}
                />

                <a href='#' className='prev' onClick={this.prevFn}>
                  <img src={PrevIcon}></img>
                </a>
                <a href='#' className='next' onClick={this.nextFn}>
                <img src={NextIcon}></img>
                </a>

              </div>
            </div>
          </div>

          <div className='container-fluid'>
            <div className='row section-04'>
              <div className='col-lg-12 col-uno' id='transformacion'>
                <img src={this.state.src_banner_01}></img>
                <div className='cuadro-banner'>{t('02.s4.title')}</div>
                <span className='piedeimagen-banner'>{t('EL.PALACIO.DE.HIERRO.POLANCO')}</span>
              </div>
            </div>
          </div>

          <div className='container'>
            <div className='row section-05'>
              <div className='col-lg-6 col-uno'>
                {parse(t('02.s5.text1'))}
              </div>
              <div className='col-lg-6 col-dos'>
                {parse(t('02.s5.text2'))}
              </div>
            </div>
          </div>

          <div className='container'>
            <div className='row section-07'>
              <div className='col-lg-6 col-uno'>
                <p className='quoted'>
                  {parse(t('02.s7.quote'))}
                </p>
              </div>
              <div className='col-lg-6 col-dos'>
                <div className='luxy-el' data-speed-y={(this.state.is_parallax_active == true) ? -2 : 0} data-offset={(this.state.is_parallax_active == true) ? 150 : 0}>
                  <img src={CarolinaHerrarBanner}></img>
                  <span className='piedeimagen'>{t('EL.PALACIO.DE.HIERRO.POLANCO')}</span>
                </div>
              </div>
            </div>
          </div>

          <div className='container-fluid'>
            <div className='row section-08'>
              <div className='col-lg-12 col-uno'>
                <img src={this.state.src_banner_02}></img>
                <span className='piedeimagen-banner'>EL PALACIO DE HIERRO SANTA FE</span>
              </div>
            </div>
          </div>

          <div className='container'>
            <div className='row section-09'>
              <div className='col-lg-12 col-cero'>
                <p className='parrafo-titulo'>{t('02.s9.title')}</p>
              </div>
              <div className='col-lg-6 col-uno'>
                {parse(t('02.s9.text1'))}
              </div>
              <div className='col-lg-6 col-dos'>
                {parse(t('02.s9.text2'))}
                <p className="quoted">
                  {parse(t('02.s9.quote'))}
                </p>
              </div>
            </div>
          </div>

          <div className='container-fluid'>
            <div className='row section-10'>
              <div className='col-lg-12 col-uno'>
                <img src={this.state.src_banner_03}></img>
                <span className='piedeimagen-banner'>{t('EL.PALACIO.DE.HIERRO.PERISUR')}</span>
              </div>
            </div>
          </div>

          <div className='container'>
            <div className='row section-11'>

              <div className='col-lg-6 col-uno'>
                <p className="quoted">
                  {parse(t('02.s11.quote'))}
                </p>
              </div>

              <div className='col-lg-6 col-dos'>
                {parse(t('02.s11.text1'))}
              </div>
            
            </div>
          </div>

          <div className='container-fluid'>
            <div className='row section-12'>
              <div className='col-lg-12 col-uno' id='construyendo'>
                <img src={this.state.src_banner_04}></img>
                <div className='cuadro-banner'>
                  {parse(t('02.s12.title'))}
                </div>
                <span className='piedeimagen-banner'>{t('EL.PALACIO.DE.HIERRO.PERISUR')}</span>
              </div>
            </div>
          </div>

          <div className='container'>
            <div className='row section-13'>
              <div className='col-lg-6 col-uno'>
                {parse(t('02.s13.text1'))}
              </div>
              <div className='col-lg-6 col-dos'>
                {parse(t('02.s13.text2'))}
                <p className="quoted">
                  {parse(t('02.s13.quote'))}
                </p>
              </div>
            </div>
          </div>

          <div className='container'>
            <div className='row section-23'>
              <div className='col-lg-12 col-uno titulo-renovacion'>
                <span className='line'></span>
                <span className='texto'>{t('02.s23.span2')}</span>
              </div>
            </div>
          </div>

          <div className='container-fluid'>
            <div className='row section-14'>
              <div className='col-lg-12 col-uno'>

                <AliceCarousel
                  ref={this.slider02Ref}
                  infinite={true}
                  paddingRight={40}
                  mouseTracking
                  items={items02}
                  responsive={responsive}
                  disableDotsControls={true}
                  disableButtonsControls={true}
                />

                <a href='#' className='prev' onClick={this.prevFn02}>
                  <img src={PrevIcon}></img>
                </a>
                <a href='#' className='next' onClick={this.nextFn02}>
                  <img src={NextIcon}></img>
                </a>

              </div>
            </div>
          </div>

          {/* <div className='container-fluid'>
            <div className='row section-15'>
              <div className='col-lg-6 col-uno'>
              </div>
              <div className='col-lg-6 col-dos'>
                <span>Reabrimos</span>
                <span>nuestras puertas</span>
                <span>y nuestros corazones</span>
                <span className='space'></span>
                <p>
                  Estamos de vuelta en El Palacio de Hierro Puebla<br/>
                  y en el Totalmente renovado<br/>
                  <a href='https://elpalaciodehierro.com' target='_blank'>elpalaciodehierro<span>.com</span></a>
                </p>
              </div>
            </div>
          </div> */}

          <div className='container'>
            <div className='row section-15-1'>
              <div className='col-lg-12 col-uno'>
                {/* <img src={BannerTiffani}></img> */}
                <VideoPlayer poster={ReaperturaPoster} video={ReaperturaVideo}></VideoPlayer>
              </div>
            </div>
          </div>

          <div className='container-fluid'>
            <div className='row section-16'>
              <div className='col-lg-12 col-uno' id='responsabilidad'>
                <img src={this.state.src_banner_05}></img>
                <div className='cuadro-banner'>{parse(t('02.s16.title'))}</div>
                <span className='piedeimagen-banner'>{t('EL.PALACIO.DE.HIERRO.PERISUR')}</span>
              </div>
            </div>
          </div>

          <div className='container'>
            <div className='row section-17'>
              <div className='col-lg-6 col-cero'>
                {parse(t('02.s17.title'))}
              </div>
              <div className='col-lg-6 col-uno'></div>
              <div className='col-lg-6 col-dos'>
                {parse(t('02.s17.text1'))}
              </div>
              <div className='col-lg-6 col-tres'>
                {parse(t('02.s17.text2'))}
              </div>
            </div>
          </div>

          <div className='container'>
            <div className='row section-18'>
              <div className='col-lg-6 col-uno'>
                <p className='quoted'>
                  {parse(t('02.s18.quote'))}
                </p>
              </div>
              <div className='col-lg-6 col-dos'>
                <div className='luxy-el' data-speed-y={(this.state.is_parallax_active == true) ? -2 : 0} data-offset={(this.state.is_parallax_active == true) ? 440 : 0}>
                  <img src={AromariaBanner}></img>
                  <span className='piedeimagen'>{t('EL.PALACIO.DE.HIERRO.PERISUR')}</span>
                </div>
              </div>
            </div>
          </div>

          <div className='container-fluid'>
            <div className='row section-19'>
              <div className='col-lg-12 col-uno' id='relaciones'>
                <img src={this.state.src_banner_06}></img>
                <div className='cuadro-banner'>{parse(t('02.s19.title'))}</div>
                <span className='piedeimagen-banner'>{t('EL.PALACIO.DE.HIERRO.PERISUR')}</span>
              </div>
            </div>
          </div>

          <div className='container'>
            <div className='row section-20'>
              <div className='col-lg-6 col-uno'>
                {parse(t('02.s20.text1'))}
              </div>
              <div className='col-lg-6 col-dos'>
                {parse(t('02.s20.text2'))}
                <p className="quoted"><span className='quote01'><img src={Quote01}></img></span>
                  {parse(t('02.s20.quote'))}
                  <span className='quote02'><img src={Quote02}></img></span>
                </p>
              </div>
            </div>
          </div>

          <div className='container'>
            <div className='row section-21'>
              <div className='col-lg-12 col-uno'>
                {/* <img src={BannerTiffani}></img> */}
                <VideoPlayer poster={TiffanyPoster} video={TiffanyVideo}></VideoPlayer>
              </div>
            </div>
          </div>

          <div className='container-fluid container-section-22' ref={this.seguidoresRef}>
            <div className='row'>
              <div className='col-12'>
                
                <div className='container'>
                  <div className='row section-22'>
                    <div className='col-lg-12 col-uno'>
                      <p>{t('02.s22.title')}</p>
                    </div>
                    <div className='col-lg-6 col-dos'>
                      <img src={Grafica}></img>
                      {/* <Grafica></Grafica> */}
                    </div>
                    <div className='col-lg-6 col-tres'>

                      <div className='first'>
                        <div className='bars'>
                          <span></span>
                          <span></span>
                          <span></span>
                        </div>
                        <div className='texts'>
                          <span><span className='gris'>@</span>BELLEZAPALACIO: 5K</span>
                          <span><span className='gris'>@</span>HOMBRESPALACIO 1,3K</span>
                          <span><span className='gris'>@</span>VIAJESPALACIO 1,1K</span>
                        </div>
                      </div>

                      <div className='second'>
                        <div className='hash'>
                          <span>#</span>
                        </div>
                        <div className='texts'>
                          <div>
                            <span onClick={this.onClickNumber}>
                              {/* 829,354 */}
                              <CountUp end={829354} separator="," duration={2.75} ref={this.countUp01Ref}>
                              {({ countUpRef, start }) => (
                                <div>
                                  <span ref={countUpRef} />
                                </div>
                              )}
                              </CountUp>
                            </span>
                            <span>{t('02.s22.hash1')}</span>
                          </div>
                          <div>
                            <span>
                              {/* 77,805 */}
                              <CountUp delay={.5} end={77805} separator="," duration={2.75} ref={this.countUp02Ref}>
                              {({ countUpRef, start }) => (
                                <div>
                                  <span ref={countUpRef} />
                                </div>
                              )}
                              </CountUp>
                            </span>
                            <span>{t('02.s22.hash2')}</span>
                          </div>
                          <div>
                            <span>
                              {/* 55,725 */}
                              <CountUp delay={1} end={55725} separator="," duration={2.75} ref={this.countUp03Ref}>
                              {({ countUpRef, start }) => (
                                <div>
                                  <span ref={countUpRef} />
                                </div>
                              )}
                              </CountUp>
                            </span>
                            <span>{t('02.s22.hash3')}</span>
                          </div>
                          <div>
                            <span>
                              {/* 28,139 */}
                              <CountUp delay={1.5} end={28139} separator="," duration={2.75} ref={this.countUp04Ref}>
                              {({ countUpRef, start }) => (
                                <div>
                                  <span ref={countUpRef} />
                                </div>
                              )}
                              </CountUp>
                            </span>
                            <span>{t('02.s22.hash4')}</span>
                          </div>
                          <div>
                            <span>
                              {/* 20,659 */}
                              <CountUp delay={2} end={20659} separator="," duration={2.75} ref={this.countUp05Ref}>
                              {({ countUpRef, start }) => (
                                <div>
                                  <span ref={countUpRef} />
                                </div>
                              )}
                              </CountUp>
                            </span>
                            <span>{t('02.s22.hash5')}</span>
                          </div>
                          <div>
                            <span>
                              {/* 17,536 */}
                              <CountUp delay={2.5} end={17536} separator="," duration={2.75} ref={this.countUp06Ref}>
                              {({ countUpRef, start }) => (
                                <div>
                                  <span ref={countUpRef} />
                                </div>
                              )}
                              </CountUp>
                            </span>
                            <span>{t('02.s22.hash6')}</span>
                          </div>
                          <div>
                            <span>
                              {/* 16,215 */}
                              <CountUp delay={3} end={16215} separator="," duration={2.75} ref={this.countUp07Ref}>
                              {({ countUpRef, start }) => (
                                <div>
                                  <span ref={countUpRef} />
                                </div>
                              )}
                              </CountUp>
                            </span>
                            <span>{t('02.s22.hash7')}</span>
                          </div>
                          <div>
                            <span>
                              {/* 69,364 */}
                              <CountUp delay={3.5} end={69364} separator="," duration={2.75} ref={this.countUp08Ref}>
                              {({ countUpRef, start }) => (
                                <div>
                                  <span ref={countUpRef} />
                                </div>
                              )}
                              </CountUp>
                            </span>
                            <span>{t('02.s22.hash8')}</span>
                          </div>
                        </div>
                      </div>

                      <div className='third'>
                        <span>{t('02.s22.span')}</span>
                      </div>

                    </div>
                  </div>
                </div>
              
              </div>
            </div>
          </div>

          <ControlsPage prev={this.props.prev} next={this.props.next}></ControlsPage>

        </div>
      </div>

      <ControlsPageMobile prev={this.props.prev} next={this.props.next}></ControlsPageMobile>

      </>
    );
  }
}

export default withTranslation()(GrupoPalacioDeHierro);