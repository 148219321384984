import React from 'react';
import cookie from 'react-cookies';
import { withTranslation } from 'react-i18next';
import Luxy from 'luxy.js';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import './InformeConsejoAsamblea.css';

import HeroBgImageVideo from '../video/video_fachada-polanco.jpg';
import HeroVideo from '../video/video_fachada-polanco.mp4';

import TiffanyVideo from '../video/video-bolsas-y-zapatos-2020.mp4';
import TiffanyPoster from '../video/video-bolsas-y-zapatos-2020.jpg';

import LogoPalacio01 from '../images/white-logo-01.svg';
import MouseIcon from '../images/scroll-icon.svg';

import Banner01Tiffani from '../images/01-quote-01-tiffani-co@2x.jpg';

import Alberto from '../images/01-foto-don-alberto@2x.jpg';
import BannerTiffani from '../images/01-quote-01-tiffani-co@2x.jpg';
import NextIcon from '../images/forward-icon.svg';
import PrevIcon from '../images/backward-icon.svg';
import LibroAmarillo from '../images/01-historia-extraordinaria@2x.jpg';
import FirmaAlberto from '../images/01-firma-don-alberto@2x.jpg';

import GalleryItem00 from '../images/01-galeria-06-gucci@2x.jpg';
import GalleryItem01 from '../images/01-galeria-01-bulgary@2x.jpg';
import GalleryItem02 from '../images/01-galeria-02-bottega-venetta@2x.jpg';
import GalleryItem03 from '../images/01-galeria-03-cartier@2x.jpg';
import GalleryItem04 from '../images/02-1-galeria-01-dior@2x.jpg';
import GalleryItem05 from '../images/01-galeria-05-louis-vuitton@2x.jpg';

import GalleryItemMobile00 from '../images/01-galeria-06-gucci-mobile@2x.jpg';
import GalleryItemMobile01 from '../images/01-galeria-01-bulgary-mobile@2x.jpg';
import GalleryItemMobile02 from '../images/01-galeria-02-bottega-venetta-mobile@2x.jpg';
import GalleryItemMobile03 from '../images/01-galeria-03-cartier-mobile@2x.jpg';
import GalleryItemMobile04 from '../images/02-1-galeria-01-dior-mobile@2x.jpg';
import GalleryItemMobile05 from '../images/01-galeria-05-louis-vuitton-mobile@2x.jpg';

import ControlsPage from '../components/ControlsPage';
import ControlsPageMobile from '../components/ControlsPageMobile';
import VideoPlayer from '../components/VideoPlayer';

import { isBrowser, isMobile, isTablet } from "react-device-detect";

import Quote01 from '../images/quote01.png';
import Quote02 from '../images/quote02.png';

import parse from 'html-react-parser';


class InformeConsejoAsamblea extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      src_banner_01: '',
      is_parallax_active: true,
    };
    this.myRef = React.createRef();
    this.videoRef = React.createRef();
    this.heroRef = React.createRef();
  }

  componentDidMount = () => {
    this.setLanguage();
    window.scrollTo(0, 0);
    window.addEventListener('scroll', this.onScroll);
    window.addEventListener('resize', this.onWindowResize, true);
    this.onWindowResize();
  }

  componentWillUnmount = () => {
    window.removeEventListener('scroll', this.onScroll);
    window.removeEventListener('resize', this.onWindowResize);
  }

  componentDidUpdate() {
      
  }

  setLanguage = () => {
    const { t, i18n } = this.props;
    const lang = cookie.load('lng');
    if (lang == undefined) {
      i18n.changeLanguage('es');
      cookie.save('lng', 'es');
    } else {
      if (lang == 'es') {
        i18n.changeLanguage('es');
      } else {
        i18n.changeLanguage('en');
      }
    }
  }

  onScroll = (event) => {
    const h = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    const positionY = window.pageYOffset;
    if (positionY >= h) {
      this.videoRef.current.pause();
      this.videoRef.current.style.display = 'none';
      this.heroRef.current.style.display = 'none';
    } else {
      this.videoRef.current.play();
      this.videoRef.current.style.display = 'block';
      this.heroRef.current.style.display = 'block';
    }
  }

  onWindowResize = () => {
    this.w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    
    if (this.w <= 768) {
      this.setState({
        is_parallax_active: false
      });  
    } else {
      this.setState({
        is_parallax_active: true
      });  
    }

    if (isBrowser) {
      // Luxy.init({wrapperSpeed: 0.2});
    }
  }

  onLoadedMetadata = (event) => {
    
  }

  nextFn = (event) => {
    event.preventDefault();
    this.myRef.current.slideNext();
  }

  prevFn = (event) => {
    event.preventDefault();
    this.myRef.current.slidePrev();
  }

  render () {

    const { t } = this.props;

    const responsive = {
      0: { items: 1 },
      568: { items: 1 },
      1024: { items: 1 },
    };
  
    const items = [
      <div className="item" data-value="1">
        <img src={(isBrowser || isTablet) ? GalleryItem00 : GalleryItemMobile00} />
        <span className='piedeimagen-carousel'>{t('EL.PALACIO.DE.HIERRO.SANTA.FE')}</span>
      </div>,
      <div className="item" data-value="2">
        <img src={(isBrowser || isTablet) ? GalleryItem01 : GalleryItemMobile01} />
        <span className='piedeimagen-carousel'>{t('EL.PALACIO.DE.HIERRO.SANTA.FE')}</span>
      </div>,
      <div className="item" data-value="3">
        <img src={(isBrowser || isTablet) ? GalleryItem02 : GalleryItemMobile02} />
        <span className='piedeimagen-carousel'>{t('EL.PALACIO.DE.HIERRO.SANTA.FE')}</span>
      </div>,
      <div className="item" data-value="4">
        <img src={(isBrowser || isTablet) ? GalleryItem03 : GalleryItemMobile03} />
        <span className='piedeimagen-carousel'>{t('EL.PALACIO.DE.HIERRO.SANTA.FE')}</span>
      </div>,
      <div className="item" data-value="5">
        <img src={(isBrowser || isTablet) ? GalleryItem04 : GalleryItemMobile04} />
        <span className='piedeimagen-carousel'>{t('EL.PALACIO.DE.HIERRO.SANTA.FE')}</span>
      </div>,
      <div className="item" data-value="6">
        <img src={(isBrowser || isTablet) ? GalleryItem05 : GalleryItemMobile05} />
        <span className='piedeimagen-carousel'>{t('EL.PALACIO.DE.HIERRO.SANTA.FE')}</span>
      </div>,
    ];

    return (
      <>

      <div className='hero-consejo-asamblea'>

        <video ref={this.videoRef} className="bg-video" poster={HeroBgImageVideo} autoPlay data-autoplay muted loop playsInline webkit-playsinline="true" preload="metadata" onLoadedData={this.onLoadedMetadata}>
          <source src={HeroVideo} type="video/mp4"></source>
        </video>
        
        <div className='container-fluid hero-container' ref={this.heroRef}>
          <div className='row hero'>
            <div className='col-uno'>
                <span></span>
            </div>
            <div className='col-dos'>
              <div className='texto'>
                <span>{t('01.hero.title.span.01')}</span>
                <span>{ parse(t('01.hero.title.span.02')) }</span>
                <span>{t('01.hero.title.span.03')}</span>
                <span>{t('01.hero.title.span.04')}</span>
              </div>
            </div>
          </div>
          <div className='mouse-scroll-icon'>
            <img src={MouseIcon}></img>
            <span>{t('01.span.mouse.message')}</span>
          </div>
        </div>

        <div className='logo-hero'>
          <img src={LogoPalacio01}></img>
        </div>

      </div>

      <div className='informeconsejoasamblea' id="luxy">
        <div style={{ background: '#fff' }}>

          <div className='texto-lateral-seccion01'>{parse(t('01.texto.lat'))}</div>

          <div className='container'>
            <div className='row section-01'>
              <div className='col-lg-6 col-uno'>
                <h1 className='h-pag'>
                  { parse(t('01.h1')) }
                </h1>
              </div>
              <div className='col-lg-6 col-dos'>
                <p>
                  { parse(t('01.s1.p1')) }
                </p>
                <p>
                  {t('01.s1.p2')}
                </p>
              </div>
            </div>
          </div>

          <div className='container'>
            <div className='row section-03'>
              <div className='col-lg-6 col-uno'>
                <h3>
                  <span className='line'></span>
                  <span className='text'>{t('01.s3.title')}</span>
                </h3>
                {parse(t('01.s3.text1'))}
              </div>
              <div className='col-lg-6 col-dos'>
                <img className='luxy-el' data-speed-y={(this.state.is_parallax_active == true) ? -5 : 0} data-offset={(this.state.is_parallax_active == true) ? 250 : 0} src={Alberto}></img>
              </div>
            </div>
          </div>

          <div className='container'>
            <div className='row section-02'>
              <div className='col-lg-6 col-uno'>
                <div className='luxy-el' data-speed-y={(this.state.is_parallax_active == true) ? -3 : 0} data-offset={(this.state.is_parallax_active == true) ? 150 : 0}>
                  <img src={Banner01Tiffani}></img>
                  <span className='piedeimagen'>{t('01.s2.piedeimagen')}</span>
                </div>
              </div>
              <div className='col-lg-6 col-dos'>
                <p className='quoted'>
                  <span className='quote01'><img src={Quote01}></img></span>
                  { parse(t('01.s2.quote')) }
                  <span className='quote02'><img src={Quote02}></img></span>
                </p>
              </div>
            </div>
          </div>

          <div className='container'>
            <div className='row section-05'>
              <div className='col-lg-6 col-uno'>
                {parse(t('01.s5.text1'))}
              </div>
              <div className='col-lg-6 col-dos'>
                {parse(t('01.s5.text2'))}
              </div>
            </div>
          </div>

          <div className='container'>
            <div className='row section-07'>
              <div className='col-lg-6 col-dos'>
                <p className='quoted'><span className='quote01'><img src={Quote01}></img></span>
                {parse(t('01.s7.quote'))}
                <span className='quote02'><img src={Quote02}></img></span></p>
              </div>
              <div className='col-lg-6 col-uno'>
                <div className='luxy-el' data-speed-y={(this.state.is_parallax_active == true) ? -3 : 0} data-offset={(this.state.is_parallax_active == true) ? 200 : 0}>
                  <img src={LibroAmarillo}></img>
                  <span className='piedeimagen'>{t('01.s7.piedeimagen')}</span>
                </div>
              </div>
            </div>
          </div>

          <div className='container'>
            <div className='row section-08'>
              <div className='col-lg-6 col-uno'>
                {parse(t('01.s8.text1'))}
              </div>
              <div className='col-lg-6 col-dos'>
                {parse(t('01.s8.text2'))}
                <p className='datos-firma'>
                  {parse(t('01.s8.datos.firma'))}
                </p>
                <img src={FirmaAlberto}></img>
              </div>
            </div>
          </div>

          <div className='container'>
            <div className='row section-04'>
              <div className='col-lg-12 col-uno'>
                {/* <img src={BannerTiffani}></img> */}
                <VideoPlayer poster={TiffanyPoster} video={TiffanyVideo}></VideoPlayer>
              </div>
            </div>
          </div>

          <div className='container'>
            <div className='row section-09'>
              <div className='col-lg-12 col-uno titulo-renovacion'>
                <span className='line'></span>
                <span className='texto'>{t('01.s9.span')}</span>
              </div>
            </div>
          </div>

          <div className='container-fluid'>
            <div className='row section-06'>
              <div className='col-lg-12 col-uno'>

                <AliceCarousel
                  ref={this.myRef}
                  infinite={true}
                  paddingRight={40}
                  mouseTracking
                  items={items}
                  responsive={responsive}
                  disableDotsControls={true}
                  disableButtonsControls={true}
                />

                <a href='#' className='prev' onClick={this.prevFn}>
                  <img src={PrevIcon}></img>
                </a>
                <a href='#' className='next' onClick={this.nextFn}>
                <img src={NextIcon}></img>
                </a>

              </div>
            </div>
          </div>

          <ControlsPage prev={this.props.prev} next={this.props.next}></ControlsPage>

        </div>
      </div>

      <ControlsPageMobile prev={this.props.prev} next={this.props.next}></ControlsPageMobile>

      </>
    );
  }
}

export default withTranslation()(InformeConsejoAsamblea);