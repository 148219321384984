import React from 'react';
import cookie from 'react-cookies';
import { withTranslation } from 'react-i18next';
import Luxy from 'luxy.js';
import {TweenMax} from "gsap";
import {Quart, Quint, Linear} from "gsap";
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import './DivisionComercial.css';

import NextIcon from '../images/forward-icon.svg';
import PrevIcon from '../images/backward-icon.svg';

import ControlsPage from '../components/ControlsPage';
import ControlsPageMobile from '../components/ControlsPageMobile';
import VideoPlayer from '../components/VideoPlayer';

import { isBrowser, isMobile, isTablet } from "react-device-detect";

import Quote01 from '../images/quote01.png';
import Quote02 from '../images/quote02.png';

import Banner01SantaFe from '../images/03-banner-01-santa-fe@2x.jpg';
import Banner01SantaFeMobile from '../images/03-banner-01-santa-fe-mobile@2x.jpg';

import Banner02SantaFe from '../images/03-banner-02-tienda@2x.jpg';
import Banner02SantaFeMobile from '../images/03-banner-02-tienda-mobile@2x.jpg';

import Banner02Perisur from '../images/03-banner-03-saccaro@2x.jpg';
import Banner02PerisurMobile from '../images/03-banner-03-saccaro-mobile@2x.jpg';

import Banner01Perisur from '../images/03-banner-06-perisur@2x.jpg';
import Banner01PerisurMobile from '../images/03-banner-06-perisur-mobile@2x.jpg';

import Banner01Polanco from '../images/03-banner-04-polanco@2x.jpg';
import Banner01PolancoMobile from '../images/03-banner-04-polanco-mobile@2x.jpg'

import Banner01Tiffani from '../images/03-quote-01-monterrey@2x.jpg';
import Banner01Samsung from '../images/03-quote-03-samsung@2x.jpg';

import GalleryItem01 from '../images/03-1-galeria-01-pronovias@2x.jpg';
import GalleryItem02 from '../images/03-1-galeria-02-gucci@2x.jpg';
import GalleryItem03 from '../images/03-1-galeria-03-balenciaga@2x.jpg';
import GalleryItem04 from '../images/03-1-galeria-04-burberry@2x.jpg';
import GalleryItem05 from '../images/03-1-galeria-05-valentino@2x.jpg';

import GalleryItemMobile01 from '../images/03-1-galeria-01-pronovias-mobile@2x.jpg';
import GalleryItemMobile02 from '../images/03-1-galeria-02-gucci-mobile@2x.jpg';
import GalleryItemMobile03 from '../images/03-1-galeria-03-balenciaga-mobile@2x.jpg';
import GalleryItemMobile04 from '../images/03-1-galeria-04-burberry-mobile@2x.jpg';
import GalleryItemMobile05 from '../images/03-1-galeria-05-valentino-mobile@2x.jpg';

import Gallery02Item01 from '../images/03-2-galeria-01-rimowa@2x.jpg';
import Gallery02Item02 from '../images/03-2-galeria-02-tory-burch@2x.jpg';
import Gallery02Item03 from '../images/03-2-galeria-03-coach@2x.jpg';
import Gallery02Item04 from '../images/03-2-galeria-04-omega@2x.jpg';
import Gallery02Item05 from '../images/03-2-galeria-05-thomas-sabo@2x.jpg';

import Gallery02ItemMobile01 from '../images/03-2-galeria-01-rimowa-mobile@2x.jpg';
import Gallery02ItemMobile02 from '../images/03-2-galeria-02-tory-burch-mobile@2x.jpg';
import Gallery02ItemMobile03 from '../images/03-2-galeria-03-coach-mobile@2x.jpg';
import Gallery02ItemMobile04 from '../images/03-2-galeria-04-omega-mobile@2x.jpg';
import Gallery02ItemMobile05 from '../images/03-2-galeria-05-thomas-sabo-mobile@2x.jpg';

import CarolinaHerrarBanner from '../images/03-quote-02-dolce-gabbana@2x.jpg';

import Video01 from '../video/video-belleza-2020.mp4';
import Poster01 from '../video/video-belleza-2020.jpg';

import Video02 from '../video/video-santa-fe.mp4';
import Poster02 from '../video/video-santa-fe.jpg';

import parse from 'html-react-parser';

class DivisionComercial extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      src_banner_01: '',
      src_banner_02: '',
      src_banner_03: '',
      src_banner_04: '',
      src_banner_05: '',
      is_parallax_active: true,
    };

    this.slider01Ref = React.createRef();
    this.slider02Ref = React.createRef();
  }

  componentDidMount = () => {
    this.setLanguage();
    window.scrollTo(0, 0);
    window.addEventListener('scroll', this.onScroll);
    window.addEventListener('resize', this.onWindowResize, true);
    this.onWindowResize();
    setTimeout(() => { 
      this.onScroll();
      const element = document.getElementById(window.location.hash.replace("#", ""));
      window.scrollTo({
        behavior: element ? "smooth" : "auto",
        top: element ? element.offsetTop : 0
      });
    }, 200);
  }

  componentWillUnmount = () => {
    window.removeEventListener('scroll', this.onScroll);
    window.removeEventListener('resize', this.onWindowResize);
  }

  componentDidUpdate() {
      
  }

  setLanguage = () => {
    const { t, i18n } = this.props;
    const lang = cookie.load('lng');
    if (lang == undefined) {
      i18n.changeLanguage('es');
      cookie.save('lng', 'es');
    } else {
      if (lang == 'es') {
        i18n.changeLanguage('es');
      } else {
        i18n.changeLanguage('en');
      }
    }
  }

  onScroll = (event) => {
    const h = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    const positionY = window.pageYOffset;
    if (positionY >= h) {
      
    } else {
      
    }
  }

  onWindowResize = () => {
    this.w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    
    if (this.w <= 576) {
      this.setState({
        src_banner_01: Banner01SantaFeMobile,
        src_banner_02: Banner02SantaFeMobile,
        src_banner_03: Banner01PerisurMobile,
        src_banner_04: Banner01PolancoMobile,
        src_banner_05: Banner02PerisurMobile,
      });
    } else {
      this.setState({
        src_banner_01: Banner01SantaFe,
        src_banner_02: Banner02SantaFe,
        src_banner_03: Banner01Perisur,
        src_banner_04: Banner01Polanco,
        src_banner_05: Banner02Perisur,
      });
    }

    if (this.w <= 768) {
      this.setState({
        is_parallax_active: false
      });  
    } else {
      this.setState({
        is_parallax_active: true
      });  
    }
    
    if (isBrowser) {
    //   Luxy.init({wrapperSpeed: 0.2});
    }
  }

  onLoadedMetadata = (event) => {
    
  }

  nextFn = (event) => {
    event.preventDefault();
    this.slider01Ref.current.slideNext();
  }

  prevFn = (event) => {
    event.preventDefault();
    this.slider01Ref.current.slidePrev();
  }

  nextFn02 = (event) => {
    event.preventDefault();
    this.slider02Ref.current.slideNext();
  }

  prevFn02 = (event) => {
    event.preventDefault();
    this.slider02Ref.current.slidePrev();
  }

  render () {

    const { t } = this.props;

    const responsive = {
      0: { items: 1 },
      568: { items: 1 },
      1024: { items: 1 },
    };

    const items = [
        <div className="item" data-value="1">
          <img src={(isBrowser || isTablet) ? GalleryItem01 : GalleryItemMobile01} />
          <span className='piedeimagen-carousel'>{t('EL.PALACIO.DE.HIERRO.PERISUR')}</span>
        </div>,
        <div className="item" data-value="2">
          <img src={(isBrowser || isTablet) ? GalleryItem02 : GalleryItemMobile02} />
          <span className='piedeimagen-carousel'>{t('EL.PALACIO.DE.HIERRO.PERISUR')}</span>
        </div>,
        <div className="item" data-value="3">
          <img src={(isBrowser || isTablet) ? GalleryItem03 : GalleryItemMobile03} />
          <span className='piedeimagen-carousel'>{t('EL.PALACIO.DE.HIERRO.PERISUR')}</span>
        </div>,
        <div className="item" data-value="4">
          <img src={(isBrowser || isTablet) ? GalleryItem04 : GalleryItemMobile04} />
          <span className='piedeimagen-carousel'>{t('EL.PALACIO.DE.HIERRO.PERISUR')}</span>
        </div>,
        <div className="item" data-value="5">
          <img src={(isBrowser || isTablet) ? GalleryItem05 : GalleryItemMobile05} />
          <span className='piedeimagen-carousel'>{t('EL.PALACIO.DE.HIERRO.PERISUR')}</span>
        </div>,
    ];

    const items02 = [
        <div className="item" data-value="1">
          <img src={(isBrowser || isTablet) ? Gallery02Item01 : Gallery02ItemMobile01} />
          <span className='piedeimagen-carousel'>{t('EL.PALACIO.DE.HIERRO.PERISUR')}</span>
        </div>,
        <div className="item" data-value="2">
          <img src={(isBrowser || isTablet) ? Gallery02Item02 : Gallery02ItemMobile02} />
          <span className='piedeimagen-carousel'>{t('EL.PALACIO.DE.HIERRO.PERISUR')}</span>
        </div>,
        <div className="item" data-value="3">
          <img src={(isBrowser || isTablet) ? Gallery02Item03 : Gallery02ItemMobile03} />
          <span className='piedeimagen-carousel'>{t('EL.PALACIO.DE.HIERRO.PERISUR')}</span>
        </div>,
        <div className="item" data-value="4">
          <img src={(isBrowser || isTablet) ? Gallery02Item04 : Gallery02ItemMobile04} />
          <span className='piedeimagen-carousel'>{t('EL.PALACIO.DE.HIERRO.PERISUR')}</span>
        </div>,
        <div className="item" data-value="5">
          <img src={(isBrowser || isTablet) ? Gallery02Item05 : Gallery02ItemMobile05} />
          <span className='piedeimagen-carousel'>{t('EL.PALACIO.DE.HIERRO.PERISUR')}</span>
        </div>,
    ];

    return (
      <>

      <div className='hero-division-comercial'>
        
        <div className='container-fluid hero-container'>
            <div className='row hero'>
                <span className='piedeimagen'>{t('EL.PALACIO.DE.HIERRO.SANTA.FE')}</span>
            </div>
        </div>

      </div>

      <div className='divisioncomercial' id="luxy">
        <div style={{ background: '#fff' }}>

            <div className='texto-lateral-seccion03'>{t('03.texto.lat')}</div>

            <div className='container'>
                <div className='row section-01'>
                    <div className='col-lg-6 col-uno'>
                        <h1 className='h-pag'>
                            {parse(t('03.h1'))}
                        </h1>
                    </div>
                    <div className='col-lg-6 col-dos'>
                        
                    </div>
                </div>
            </div>
         
            <div className='container-fluid'>
                <div className='row section-02'>
                    <div className='col-lg-12 col-uno' id='lujo'>
                        <img src={this.state.src_banner_01}></img>
                        <div className='cuadro-banner'>
                            {parse(t('03.s2.title'))}
                        </div>
                        <span className='piedeimagen-banner'>{t('EL.PALACIO.DE.HIERRO.SANTA.FE')}</span>
                    </div>
                </div>
            </div>

            <div className='container'>
                <div className='row section-03'>
                    <div className='col-lg-6 col-uno'>
                        {parse(t('03.s3.text1'))}
                    </div>
                    <div className='col-lg-6 col-dos'>
                        {parse(t('03.s3.text2'))}
                    </div>
                </div>
            </div>

            <div className='container'>
                <div className='row section-04'>
                    <div className='col-lg-6 col-uno'>
                        <div className='luxy-el' data-speed-y={(this.state.is_parallax_active == true) ? -3 : 0} data-offset={(this.state.is_parallax_active == true) ? 90 : 0}>
                            <img src={Banner01Tiffani}></img>
                            <span className='piedeimagen'>{t('PERSONAL.SHOPPING.EL.PALACIO.DE.HIERRO.MONTERREY')}</span>
                        </div>
                    </div>
                    <div className='col-lg-6 col-dos'>
                        <p className='quoted'>
                            <span className='quote01'><img src={Quote01}></img></span>
                                {t('03.s4.quote')}
                            <span className='quote02'><img src={Quote02}></img></span>
                        </p>
                    </div>
                </div>
            </div>

            <div className='container'>
                <div className='row section-05'>
                    <div className='col-lg-12 col-uno titulo-renovacion'>
                        <span className='line'></span>
                        <span className='texto'>{t('03.s5.title')}</span>
                    </div>
                </div>
            </div>

            <div className='container-fluid'>
                <div className='row section-06'>
                    <div className='col-lg-12 col-uno'>

                        <AliceCarousel
                            ref={this.slider01Ref}
                            infinite={true}
                            paddingRight={40}
                            mouseTracking
                            items={items}
                            responsive={responsive}
                            disableDotsControls={true}
                            disableButtonsControls={true}
                        />

                        <a href='#' className='prev' onClick={this.prevFn}>
                            <img src={PrevIcon}></img>
                        </a>
                        <a href='#' className='next' onClick={this.nextFn}>
                            <img src={NextIcon}></img>
                        </a>

                    </div>
                </div>
            </div>

            <div className='container-fluid'>
                <div className='row section-07'>
                    <div className='col-lg-12 col-uno' id='moda'>
                        <img src={this.state.src_banner_02}></img>
                        <div className='cuadro-banner'>
                            {parse(t('03.s7.title'))}
                        </div>
                        <span className='piedeimagen-banner'>{t('EL.PALACIO.DE.HIERRO.SANTA.FE')}</span>
                    </div>
                </div>
            </div>

            <div className='container'>
                <div className='row section-08'>
                    <div className='col-lg-6 col-uno'>
                        {parse(t('03.s8.text1'))}
                    </div>
                    <div className='col-lg-6 col-dos'>
                        {parse(t('03.s8.text2'))}
                    </div>
                </div>
            </div>

            <div className='container'>
                <div className='row section-09'>
                    <div className='col-lg-6 col-uno'>
                        <p className='quoted'>
                            {parse(t('03.s9.quote'))}
                        </p>
                    </div>
                    <div className='col-lg-6 col-dos'>
                        <div className='luxy-el' data-speed-y={(this.state.is_parallax_active == true) ? -3 : 0} data-offset={(this.state.is_parallax_active == true) ? 270 : 0}>
                            <img src={CarolinaHerrarBanner}></img>
                            <span className='piedeimagen'>{t('EL.PALACIO.DE.HIERRO.SANTA.FE')}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className='container'>
                <div className='row section-20'>
                    <div className='col-lg-12 col-uno titulo-renovacion'>
                        <span className='line'></span>
                        <span className='texto'>{t('03.s20.title')}</span>
                    </div>
                </div>
            </div>

            <div className='container'>
                <div className='row section-10'>
                <div className='col-lg-12 col-uno'>
                    <VideoPlayer poster={Poster02} video={Video02}></VideoPlayer>
                </div>
                </div>
            </div>

            <div className='container-fluid'>
                <div className='row section-11'>
                    <div className='col-lg-12 col-uno' id='casa'>
                        <img src={this.state.src_banner_05}></img>
                        <div className='cuadro-banner'>
                            {parse(t('03.s11.title'))}
                        </div>
                        <span className='piedeimagen-banner'>{t('EL.PALACIO.DE.HIERRO.PERISUR')}</span>
                    </div>
                </div>
            </div>

            <div className='container'>
                <div className='row section-12'>
                    <div className='col-lg-6 col-uno'>
                        {parse(t('03.s12.text1'))}
                    </div>
                    <div className='col-lg-6 col-dos'>
                        {parse(t('03.s12.text2'))}
                    </div>
                </div>
            </div>

            <div className='container'>
                <div className='row section-13'>
                    <div className='col-lg-6 col-uno'>
                        <div className='luxy-el' data-speed-y={(this.state.is_parallax_active == true) ? -3 : 0} data-offset={(this.state.is_parallax_active == true) ? 400 : 0}>
                            <img src={Banner01Samsung}></img>
                            <span className='piedeimagen'>{t('EL.PALACIO.DE.HIERRO.SANTA.FE')}</span>
                        </div>
                    </div>
                    <div className='col-lg-6 col-dos'>
                        <p className='quoted'>
                            <span className='quote01'><img src={Quote01}></img></span>
                                {parse(t('03.s13.quote'))}
                            <span className='quote02'><img src={Quote02}></img></span>
                        </p>
                    </div>
                </div>
            </div>

            <div className='container-fluid'>
                <div className='row section-14'>
                    <div className='col-lg-12 col-uno' id='boutiques'>
                        <img src={this.state.src_banner_04}></img>
                        <div className='cuadro-banner'>
                            {parse(t('03.s14.title'))}
                        </div>
                        <span className='piedeimagen-banner'>{t('EL.PALACIO.DE.HIERRO.POLANCO')}</span>
                    </div>
                </div>
            </div>

            <div className='container'>
                <div className='row section-15'>

                    <div className='col-lg-6 col-uno'>
                        <p className="quoted">
                            <span className='quote01'><img src={Quote01}></img></span>
                            {parse(t('03.s15.quote'))}
                            <span className='quote02'><img src={Quote02}></img></span>
                        </p>
                    </div>

                    <div className='col-lg-6 col-dos'>
                        {parse(t('03.s15.text1'))}
                    </div>
                
                </div>
            </div>

            <div className='container'>
                <div className='row section-21'>
                    <div className='col-lg-12 col-uno titulo-renovacion'>
                        <span className='line'></span>
                        <span className='texto'>{t('03.s21.title')}</span>
                    </div>
                </div>
            </div>

            <div className='container-fluid'>
                <div className='row section-16'>
                    <div className='col-lg-12 col-uno'>

                        <AliceCarousel
                            ref={this.slider02Ref}
                            infinite={true}
                            paddingRight={40}
                            mouseTracking
                            items={items02}
                            responsive={responsive}
                            disableDotsControls={true}
                            disableButtonsControls={true}
                        />

                        <a href='#' className='prev' onClick={this.prevFn02}>
                            <img src={PrevIcon}></img>
                        </a>
                        <a href='#' className='next' onClick={this.nextFn02}>
                            <img src={NextIcon}></img>
                        </a>

                    </div>
                </div>
            </div>

            <div className='container-fluid'>
                <div className='row section-17'>
                    <div className='col-lg-12 col-uno' id='comercio'>
                        <img src={this.state.src_banner_03}></img>
                        <div className='cuadro-banner'>
                            {parse(t('03.s17.title'))}
                        </div>
                        <span className='piedeimagen-banner'>{t('EL.PALACIO.DE.HIERRO.PERISUR')}</span>
                    </div>
                </div>
            </div>

            <div className='container'>
                <div className='row section-18'>
                    <div className='col-lg-6 col-uno'>
                        {parse(t('03.s18.text1'))}
                    </div>
                    <div className='col-lg-6 col-dos'>
                        <p className="quoted">
                        <span className='quote01'><img src={Quote01}></img></span>
                            {parse(t('03.s18.quote'))}
                        <span className='quote02'><img src={Quote02}></img></span>
                        </p>
                    </div>
                </div>
            </div>

            <div className='container'>
                <div className='row section-19'>
                <div className='col-lg-12 col-uno'>
                    <VideoPlayer poster={Poster01} video={Video01}></VideoPlayer>
                </div>
                </div>
            </div>

            <ControlsPage prev={this.props.prev} next={this.props.next}></ControlsPage>

        </div>
      </div>

      <ControlsPageMobile prev={this.props.prev} next={this.props.next}></ControlsPageMobile>

      </>
    );
  }
}

export default withTranslation()(DivisionComercial);