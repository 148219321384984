import React from 'react';
import cookie from 'react-cookies';
import { withTranslation } from 'react-i18next';
import './Inicio.css';
import LogoPalacio from '../images/white-logo.svg';
import ReporteEstadoFinanciero from '../pdf/Estados Financieros Palacio 2020.pdf';
import parse from 'html-react-parser';

class Inicio extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount = () => {
    this.setLanguage();
  }

  componentWillUnmount = () => {

  }

  componentDidUpdate() {
  
  }

  setLanguage = () => {
    const { t, i18n } = this.props;
    const lang = cookie.load('lng');
    if (lang == undefined) {
      i18n.changeLanguage('es');
      cookie.save('lng', 'es');
    } else {
      if (lang == 'es') {
        i18n.changeLanguage('es');
      } else {
        i18n.changeLanguage('en');
      }
    }
  }

  render () {

    const { t, i18n } = this.props;

    return (
      <div className='inicio'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6 col-md-12 col-uno'>
              <img src={LogoPalacio} className='logo-palacio'></img>
              <p>
                { parse(t('inicio.p.01')) }
              </p>
              <h4>{t('inicio.h4')}</h4>
            </div>
            <div className='col-lg-6 col-md-12 col-dos'>
              <p>{ parse(t('inicio.p.02')) }</p>
              <div>
                <a href={`${process.env.PUBLIC_URL}/informe-del-consejo-a-la-asamblea`} className='palacio-button'>{t('inicio.button.01')}</a>
                <a href={`${process.env.PUBLIC_URL}/informe-financiero`} target='_self' className='palacio-button'>{t('inicio.button.02')}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

}

export default withTranslation()(Inicio);