import React, { useState } from 'react';
import {TweenMax} from "gsap";
import {Quart, Quint, Linear} from "gsap";
import cookie from 'react-cookies';
import { useTranslation } from 'react-i18next';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { useSpring, animated } from 'react-spring';
import { easeQuadOut, easeQuadIn } from 'd3-ease';
import './Navbar.css';
import menuIcon from '../images/menu-icon.svg';
import closeIcon from '../images/close-icon.svg';
import ReporteEstadoFinanciero from '../pdf/Estados Financieros Palacio 2020.pdf';

function Navbar(properties) {
    
    var toggle = -100;   
    const [props, set, stop] = useSpring(() => ({
        left: toggle + '%'
    }));
    const showSidebar = (event) => {
        properties.opennavbar(toggle);
        toggle = toggle == 0 ? -100 : 0;
        var ease = toggle == 0 ? easeQuadOut : easeQuadIn;
        set({config: { duration: 600, easing: ease }, left: toggle + '%'});
        event.preventDefault();
        
        const href = event.currentTarget.getAttribute("href");
        if (href != '#') {
            const target = event.currentTarget.getAttribute("target");
            if (target == '_blank') {
                window.open(href, target);
            } else {
                window.location.href = href;
            }
        }
    }
    const { t, i18n } = useTranslation();
    const onClickEn = (event) => {
        event.preventDefault();
        i18n.changeLanguage('en');
        cookie.save('lng', 'en');
    }
    const onClickEs = (event) => {
        event.preventDefault();
        i18n.changeLanguage('es');
        cookie.save('lng', 'es');
    }
    const sidebarData = [
        {
            title: t('nav.inicio'),
            path: `${process.env.PUBLIC_URL}/inicio`,
            icon: '',
            num: '00',
            numClassName: 'hide',
            className: 'nav-text' ,
            items: []
        },
        {
            title: t('nav.informe.consejo.asamblea'),
            path: `${process.env.PUBLIC_URL}/informe-del-consejo-a-la-asamblea`,
            icon: '',
            num: '01',
            numClassName: '',
            className: 'nav-text',
            items: []
        },
        {
            title: t('nav.grupo.palacio.de.hierro'),
            path: `${process.env.PUBLIC_URL}/grupo-palacio-de-hierro`,
            icon: '',
            num: '02',
            numClassName: '',
            className: 'nav-text',
            items: [
                {
                    title: t('nav.transformacion'),
                    path: `${process.env.PUBLIC_URL}/grupo-palacio-de-hierro` + "?c=" + (new Date()).valueOf() + `#transformacion`,
                    icon: '',
                    className: 'nav-text-sub',
                },
                {
                    title: t('nav.construyendo.equipo.futuro'),
                    path: `${process.env.PUBLIC_URL}/grupo-palacio-de-hierro` + "?c=" + (new Date()).valueOf() + `#construyendo`,
                    icon: '',
                    className: 'nav-text-sub',
                },
                {
                    title: t('nav.responsabilidad.social'),
                    path: `${process.env.PUBLIC_URL}/grupo-palacio-de-hierro` + "?c=" + (new Date()).valueOf() + `#responsabilidad`,
                    icon: '',
                    className: 'nav-text-sub',
                },
                {
                    title: t('nav.relaciones.con.clientes'),
                    path: `${process.env.PUBLIC_URL}/grupo-palacio-de-hierro` + "?c=" + (new Date()).valueOf() + `#relaciones`,
                    icon: '',
                    className: 'nav-text-sub',
                }
            ]
        },
        {
            title: t('nav.division.comercial.negocio'),
            path: `${process.env.PUBLIC_URL}/division-comercial`,
            icon: '',
            num: '03',
            numClassName: '',
            className: 'nav-text',
            items: [
                {
                    title: t('nav.lujo.palacio'),
                    path: `${process.env.PUBLIC_URL}/division-comercial` + "?c=" + (new Date()).valueOf() + `#lujo`,
                    icon: '',
                    className: 'nav-text-sub',
                },
                {
                    title: t('nav.moda.pilar.crecimiento'),
                    path: `${process.env.PUBLIC_URL}/division-comercial` + "?c=" + (new Date()).valueOf() + `#moda`,
                    icon: '',
                    className: 'nav-text-sub',
                },
                {
                    title: t('nav.casa.palacio.hogar'),
                    path: `${process.env.PUBLIC_URL}/division-comercial` + "?c=" + (new Date()).valueOf() + `#casa`,
                    icon: '',
                    className: 'nav-text-sub',
                },
                {
                    title: t('nav.tecnologia.negocio'),
                    path: `${process.env.PUBLIC_URL}/division-comercial` + "?c=" + (new Date()).valueOf() + `#casa`,
                    icon: '',
                    className: 'nav-text-sub',
                },
                {
                    title: t('nav.boutiques'),
                    path: `${process.env.PUBLIC_URL}/division-comercial` + "?c=" + (new Date()).valueOf() + `#boutiques`,
                    icon: '',
                    className: 'nav-text-sub',
                },
                {
                    title: t('nav.comercio'),
                    path: `${process.env.PUBLIC_URL}/division-comercial` + "?c=" + (new Date()).valueOf() + `#comercio`,
                    icon: '',
                    className: 'nav-text-sub',
                }
            ]
        },
        {
            title: t('nav.logistica'),
            path: `${process.env.PUBLIC_URL}/logistica`,
            icon: '',
            num: '04',
            numClassName: '',
            className: 'nav-text',
            items: []
        },
        {
            title: t('nav.finanzas'),
            path: `${process.env.PUBLIC_URL}/finanzas`,
            icon: '',
            num: '05',
            numClassName: '',
            className: 'nav-text',
            items: []
        },
        {
            title: t('nav.consejo'),
            path: `${process.env.PUBLIC_URL}/consejo-de-administracion`,
            icon: '',
            num: '06',
            numClassName: '',
            className: 'nav-text',
            items: []
        },
        {
            title: t('nav.directorio'),
            path: `${process.env.PUBLIC_URL}/directorio`,
            icon: '',
            num: '07',
            numClassName: '',
            className: 'nav-text',
            items: []
        },
    ];

    const mouseOver = (event) => {
        TweenMax.to(event.currentTarget, .2, {y: -5, ease: Linear.easeNone});
    }
    
    const mouseOut = (event) => {
        TweenMax.to(event.currentTarget, .2, {y: 0, ease: Linear.easeNone});
    }

    return (
        <>
            <div className='navbar-container'>
                <a href='#' className='menu-icon' onClick={showSidebar}>
                    <img src={menuIcon}></img>
                </a>
                <span className='contenido'>{t('nav.contenido')}</span>
                <div className='lang-container'>
                    <a className='en' href='#' onClick={onClickEn}>EN</a> 
                    <span className='lang-sep'> | </span> 
                    <a className='es' href='#' onClick={onClickEs}>ES</a>
                </div>
                <span className='elpalaciodehierro'>
                    <a style={{color: '#fff', fontFamily: 'Gotham-Bold', fontSize: '12px'}} target='_blank' href='https://www.elpalaciodehierro.com/'>{t('nav.elpalaciodehierro.com')}</a>
                </span>
            </div>
            <animated.nav className='nav-menu' style={props}>
                <div style={{ display: 'flex', width: '100%', height: 'auto', alignItems: 'center', justifyContent: 'start', paddingTop: '20px', paddingLeft: '30px', paddingRight: '30px' }}>
                    <a href='#' className='menu-icon-close' onClick={showSidebar}>
                        <img src={closeIcon}></img>
                    </a>
                    <div className='informe' style={{ marginLeft: 'auto' }}>
                        <a href={`${process.env.PUBLIC_URL}/informe-financiero`} target='_self' onClick={showSidebar}>{t('nav.ver.informe')}</a>
                    </div>
                </div>
                <ul className='nav-menu-items'>
                    {sidebarData.map((item, index) => {
                        return (
                            <li key={index} className={item.className}>
                                <a href={item.path} onClick={showSidebar} onMouseOver={mouseOver} onMouseOut={mouseOut}>
                                    <span className={'num ' + item.numClassName}>{item.num}</span>
                                    <span className='title'>{item.title}</span>
                                </a>
                                <ul className='nav-menu-subitems'>
                                    {
                                        item.items.map((subitem, i) => {
                                            return(
                                                <li key={i} className={subitem.className}>
                                                    <a href={subitem.path} onClick={showSidebar} onMouseOver={mouseOver} onMouseOut={mouseOut}>
                                                        {subitem.title}
                                                    </a>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </li>
                        );
                    })}
                </ul>
            </animated.nav>
        </>
    );
}

export default Navbar;