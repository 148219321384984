import React from 'react';
import cookie from 'react-cookies';
import { withTranslation } from 'react-i18next';
import Luxy from 'luxy.js';
import {TweenMax} from "gsap";
import {Quart, Quint, Linear} from "gsap";
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import './Finanzas.css';

import NextIcon from '../images/forward-icon.svg';
import PrevIcon from '../images/backward-icon.svg';

import ControlsPage from '../components/ControlsPage';
import ControlsPageMobile from '../components/ControlsPageMobile';
import VideoPlayer from '../components/VideoPlayer';

import { isBrowser, isMobile, isTablet } from "react-device-detect";

import Quote01 from '../images/quote01.png';
import Quote02 from '../images/quote02.png';

import Video01 from '../video/video-polanco-noche.mp4';
import Poster01 from '../video/video-polanco-noche.jpg';

import Firma from '../images/firma-juan-carlos@2x.jpg';

import CarolinaHerrarBanner from '../images/05-quote-01-tane@2x.jpg';

import parse from 'html-react-parser';

class Finanzas extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      src_banner_01: '',
      is_parallax_active: true,
    };
  }

  componentDidMount = () => {
    this.setLanguage();
    window.scrollTo(0, 0);
    window.addEventListener('scroll', this.onScroll);
    window.addEventListener('resize', this.onWindowResize, true);
    this.onWindowResize();
    setTimeout(() => { 
      this.onScroll();
    }, 200);
  }

  componentWillUnmount = () => {
    window.removeEventListener('scroll', this.onScroll);
    window.removeEventListener('resize', this.onWindowResize);
  }

  componentDidUpdate() {
      
  }

  setLanguage = () => {
    const { t, i18n } = this.props;
    const lang = cookie.load('lng');
    if (lang == undefined) {
      i18n.changeLanguage('es');
      cookie.save('lng', 'es');
    } else {
      if (lang == 'es') {
        i18n.changeLanguage('es');
      } else {
        i18n.changeLanguage('en');
      }
    }
  }

  onScroll = (event) => {
    const h = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    const positionY = window.pageYOffset;
    if (positionY >= h) {
      
    } else {
      
    }
  }

  onWindowResize = () => {
    this.w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    
    if (this.w <= 576) {
      this.setState({
        // src_banner_01: Banner01SantaFeMobile,
      });
    } else {
      this.setState({
        // src_banner_01: Banner01SantaFe,
      });
    }

    if (this.w <= 768) {
      this.setState({
        is_parallax_active: false
      });  
    } else {
      this.setState({
        is_parallax_active: true
      });  
    }
    
    if (isBrowser) {
      // Luxy.init({wrapperSpeed: 0.2});
    }
  }

  onLoadedMetadata = (event) => {
    
  }

  render () {

    const { t } = this.props;

    return (
      <>

      <div className='hero-finanzas'>
        
        <div className='container-fluid hero-container'>
            <div className='row hero'>
                <span className='piedeimagen'>{t('EL.PALACIO.DE.HIERRO.POLANCO')}</span>
            </div>
        </div>

      </div>

      <div className='finanzas' id="luxy">
        <div style={{ background: '#fff' }}>

            <div className='texto-lateral-seccion05'>{t('05.texto.lat')}</div>

            <div className='container'>
                <div className='row section-01'>
                    <div className='col-lg-6 col-uno'>
                        <h1 className='h-pag'>
                          {parse(t('05.h1'))}
                        </h1>
                    </div>
                    <div className='col-lg-6 col-dos'>
                        
                    </div>
                </div>
            </div>

            <div className='container'>
                <div className='row section-02'>

                    <div className='col-lg-6 col-uno'>
                      {parse(t('05.s2.text1'))}
                    </div>

                    <div className='col-lg-6 col-dos'>
                      {parse(t('05.s2.text2'))}

                      <p className='datos-firma'>
                          {parse(t('05.s2.datos.firma'))}
                      </p>
                      <img src={Firma}></img>
                    </div>
                
                </div>
            </div>

            <div className='container'>
                <div className='row section-07'>
                    <div className='col-lg-6 col-uno'>
                        <p className='quoted'>
                        <span className="quote01"><img src={Quote01}></img></span>
                          {parse(t('05.s7.quote'))}
                        <span className="quote02"><img src={Quote02}></img></span>
                        </p>
                    </div>
                    <div className='col-lg-6 col-dos'>
                        <div className='luxy-el' data-speed-y={(this.state.is_parallax_active == true) ? -3 : 0} data-offset={(this.state.is_parallax_active == true) ? 50 : 0}>
                        <img src={CarolinaHerrarBanner}></img>
                        <span className='piedeimagen'>{t('EL.PALACIO.DE.HIERRO.PERISUR')}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className='container'>
                <div className='row section-03'>
                    <div className='col-lg-12 col-uno titulo-renovacion'>
                        <span className='line'></span>
                        <span className='texto'>{t('05.s3.title')}</span>
                    </div>
                </div>
            </div>

            <div className='container'>
                <div className='row section-04'>
                <div className='col-lg-12 col-uno'>
                    <VideoPlayer poster={Poster01} video={Video01}></VideoPlayer>
                </div>
                </div>
            </div>

            <ControlsPage prev={this.props.prev} next={this.props.next}></ControlsPage>

        </div>
      </div>

      <ControlsPageMobile prev={this.props.prev} next={this.props.next}></ControlsPageMobile>

      </>
    );
  }
}

export default withTranslation()(Finanzas);